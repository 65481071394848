import { ActionReducerMapBuilder, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit'
import { NoInfer } from 'react-redux'
import cloneDeep from 'lodash.clonedeep'
import actionType from '../../types/utility.ts'
import { FlowJsonType } from '../../types/FlowJson.ts'
import { $FlowJsonReduxType } from '../../types/reduxCore.ts'
import {
  setFlowJsonThunk,
  publishFlowThunk,
  updateStepOrderThunk,
  createDraftStepThunk,
  handleDeleteStepsThunk,
  createOrUpdateTriggerThunk
} from './flowJsonThunkV2.ts'
import getStepOrderArray from '../../utils/flowJsonUtilities.ts'
import { BlockTypes, STEP_OPERATION_STATUS, Tabnames } from '../../enums'
import { errorToast } from '../../components/customToast'
import { getNextVersion, removeFromOrder, replaceFromOrder } from '../../utils/utilities'

const generateFlowJson = () => ({
  identifier: '', // unique id, script id
  title: '', // script Name
  order: { root: [] }, // shows order of steps present inside the root array and if block id : [inner step id]
  blocks: {}, // json object of the id contained in the root array
  versioning: {} // version of the changes in step
})
export const flowJsonSampleobj = {
  flowJson: generateFlowJson(),
  publishedFlowJson: generateFlowJson(),
  draftedFlowJson: generateFlowJson(),
  publishUsedVariables: {
    functions: {},
    variables: {}
  },
  isLoading: false,
  isCronEditable: false, // if the cron editable or not
  isCronLoading: false, //  if cron statement is loading or not
  isTriggerEditable: false, // if the trigger component is editable or not
  isTriggerLoading: false,
  stepOrder: [], // all the order of the step including if block ,var , fun/ api
  showError: false,
  metaData: {}
}
export const initialState: $FlowJsonReduxType = {}

export const reducers: ValidateSliceCaseReducers<$FlowJsonReduxType, SliceCaseReducers<$FlowJsonReduxType>> = {
  setRequiredFlowJson(state, action: actionType<string>) {
    const { scriptId } = action.urlData
    const tabName = action.payload
    if (!state[scriptId]) {
      state[scriptId] = cloneDeep(flowJsonSampleobj)
    }
    if (tabName === Tabnames.PUBLISH) {
      state[scriptId].flowJson = state[scriptId].publishedFlowJson
      const order = state[scriptId].publishedFlowJson.order
      state[scriptId].stepOrder = getStepOrderArray(order)
    }
    if (tabName === Tabnames.DRAFT) {
      state[scriptId].flowJson = state[scriptId]?.draftedFlowJson
      const order = state[scriptId].draftedFlowJson?.order || {}
      state[scriptId].stepOrder = getStepOrderArray(order)
    }
  },
  updateDraftedFlowJsonTrigger(state, action: any) {
    const { scriptId } = action.urlData
    if (state[scriptId] && state[scriptId].draftedFlowJson && state[scriptId].draftedFlowJson.trigger) {
      if (action.payload.triggerAdvanceConfigAction === 'UPDATE') {
        state[scriptId].draftedFlowJson.trigger[action.payload.triggerAdvanceConfigType] = action.payload.triggerAdvanceConfig
      }
      if (action.payload.triggerAdvanceConfigAction === 'DELETE') {
        delete state[scriptId].draftedFlowJson.trigger[action.payload.triggerAdvanceConfigType]
      }
    }
  },
  setFlowAndDraftFlowJson(state, action: actionType<any>) {
    const data = action.payload
    const { scriptId } = action.urlData
    if (!state[scriptId]) {
      state[scriptId] = cloneDeep(flowJsonSampleobj)
    }
    const order = data?.order
    state[scriptId].stepOrder = getStepOrderArray(order)
    state[scriptId].flowJson = data
    state[scriptId].draftedFlowJson = data
  },
  setMetaDataForFlowJson(state, action: actionType<any>) {
    handleFulfilled(state, action.payload)
  },
  setPublishedFlowJson(state, action: actionType<FlowJsonType>) {
    const { scriptId } = action.urlData
    state[scriptId].publishedFlowJson = action.payload
  },
  setFlowJsonAndUsedVariables(state, action: actionType<{ flowJson: any }>) {
    const { flowJson } = action.payload
    const { scriptId } = action.urlData
    state[scriptId].stepOrder = getStepOrderArray(flowJson?.order)
    // state[scriptId].usedVariables = usedVariables
    state[scriptId].flowJson = flowJson
    state[scriptId].draftedFlowJson = flowJson
  },

  setIsTriggerEditable(state, action: actionType<boolean>) {
    const { scriptId } = action.urlData
    if (!state[scriptId]) {
      state[scriptId] = cloneDeep(flowJsonSampleobj)
    }
    state[scriptId].isTriggerEditable = action.payload.data
  },
  startCreateDraftStepThunk(state, action) {
    const scriptId = action?.urlData?.scriptId
    const flowJson = cloneDeep(state[scriptId]?.flowJson || {})
    const { block, identifier, orderGroup, position, slugName } = action.payload
    flowJson.blocks = { ...flowJson.blocks, ...block }
    // if (identifier !== BlockTypes.RESPONSE) flowJson.order[orderGroup].push(identifier)
    if (identifier !== BlockTypes.RESPONSE) {
      if (typeof position === 'number' && position >= 0 && position < flowJson.order[orderGroup].length) {
        flowJson.order[orderGroup].splice(position + 1, 0, slugName)
      } else {
        // eslint-disable-next-line
        if (flowJson.order[orderGroup]) {
          flowJson.order[orderGroup]?.push(slugName)
        } else {
          flowJson.order[orderGroup] = [slugName]
        }
      }
    }
    flowJson.version = getNextVersion(flowJson.version)
    state[scriptId].flowJson = flowJson
  },
  successDraftStepThunk(state, action) {
    handleFulfilled(state, action.payload)
  },

  rejectedDraftStepThunk(state, action) {
    const scriptId = action?.urlData?.scriptId
    const draftJson = cloneDeep(state?.[scriptId]?.draftedFlowJson)
    state[scriptId].flowJson = { ...draftJson }
    state[scriptId].isLoading = false
  },
  setTemplateSetting(state, action) {
    if (!state?.[action.payload.scriptId]?.templateSettings) {
      state[action.payload.scriptId] = { ...state[action.payload.scriptId], templateSettings: {} }
    }
    state[action.payload.scriptId].templateSettings = action.payload.settings
  }
}

export function extraReducers(builder: ActionReducerMapBuilder<NoInfer<$FlowJsonReduxType>>): void {
  builder
    .addCase(setFlowJsonThunk.pending, (state, action) => {
      state[action?.payload?.id || action?.urlData?.scriptId] = {
        ...state?.[action?.payload?.id || action?.urlData?.scriptId],
        isLoading: true
      }
    })
    .addCase(setFlowJsonThunk.fulfilled, (state, action) => {
      const scriptId = action.payload?.id
      handleFulfilled(state, action?.payload)
      state[scriptId].isLoading = false
      state[scriptId].showError = false
      if (action?.payload?.projects_settings) state[scriptId].projectSettings = action?.payload?.projects_settings
      if (action?.payload?.templates_settings) state[scriptId].templateSettings = action?.payload?.templates_settings
    })

    .addCase(setFlowJsonThunk.rejected, (state, action) => {
      state[action?.urlData?.scriptId] = { ...flowJsonSampleobj, showError: true }
    })

    .addCase(publishFlowThunk.pending, (state, action) => {
      state[action?.urlData?.scriptId].isLoading = true
    })
    .addCase(publishFlowThunk.fulfilled, (state, action) => {
      handleFulfilled(state, action.payload, action?.urlData?.scriptId)
    })
    .addCase(publishFlowThunk.rejected, (state, action) => {
      state[action?.urlData?.scriptId].isLoading = false
    })

    .addCase(updateStepOrderThunk.pending, (state, action) => {
      state[action?.urlData?.scriptId].isLoading = false
    })
    .addCase(updateStepOrderThunk.fulfilled, (state, action) => {
      handleFulfilled(state, action.payload)
    })
    .addCase(updateStepOrderThunk.rejected, (state, action) => {
      // never comming here
      state[action?.urlData?.scriptId].isLoading = false
    })

    .addCase(createDraftStepThunk.pending, (state, action) => {
      const scriptId = action?.urlData?.scriptId
      const flowJson = cloneDeep(state[scriptId]?.draftedFlowJson)
      const { block, identifier, orderGroup, position, ifBlockData } = action.meta.arg
      const name = Object.keys(block)[0]
      flowJson.blocks = { ...flowJson.blocks, ...block }
      if (identifier !== BlockTypes.RESPONSE) {
        if (typeof position === 'number' && position >= 0 && position < flowJson.order[orderGroup].length) {
          flowJson.order[orderGroup].splice(position + 1, 0, name)
        } else flowJson.order[orderGroup].push(name)
      }
      const childIfBlockDetails = ifBlockData?.ifBlockToAdd?.[ifBlockData?.ifBlockSlugName]
      if (childIfBlockDetails?.type === BlockTypes.IFBLOCK) {
        flowJson.blocks = { ...flowJson?.blocks, ...ifBlockData?.ifBlockToAdd }
        if (Array.isArray(flowJson?.order?.[childIfBlockDetails?.parent])) {
          flowJson.order[childIfBlockDetails?.parent].push(childIfBlockDetails?.identifier)
        } else {
          flowJson.order[childIfBlockDetails?.parent] = [childIfBlockDetails?.identifier]
        }
        flowJson.order[childIfBlockDetails.identifier] = []
      }
      flowJson.version = getNextVersion(flowJson.version || '0.0.1')
      state[scriptId].flowJson = flowJson
      // state[scriptId].draftedFlowJson = flowJson
    })
    .addCase(createDraftStepThunk.fulfilled, (state, action) => {
      handleFulfilled(state, action?.payload)
    })
    .addCase(createDraftStepThunk.rejected, (state, action) => {
      const scriptId = action.urlData?.scriptId
      const { setSearchParams } = action.meta.arg
      const draftFlowJson = cloneDeep(state[scriptId]?.draftedFlowJson)
      state[scriptId].flowJson = { ...draftFlowJson }
      setSearchParams({})
      state[scriptId].isLoading = false
    })

    .addCase(handleDeleteStepsThunk.pending, (state, action) => {
      const scriptId = action.urlData?.scriptId
      const { slugName, stepId } = action.meta.arg
      const { flowJson, publishedFlowJson } = state?.[scriptId]
      let order = cloneDeep(flowJson?.order)
      const blocks = cloneDeep(flowJson?.blocks)
      const isStepPresentInPublishedJson =
        Object.values(publishedFlowJson?.blocks).find((block) => block.identifier === stepId) !== undefined
      if (isStepPresentInPublishedJson) {
        blocks[slugName].status = STEP_OPERATION_STATUS.DELETE
        const newSlugName = `${slugName}_DELETED`
        blocks[newSlugName] = blocks[slugName]
        replaceFromOrder(slugName, newSlugName, order)
        delete blocks[slugName]
      } else {
        order = removeFromOrder(order, slugName)
        delete blocks[slugName]
      }
      state[scriptId].flowJson = {
        ...flowJson,
        blocks,
        order,
        version: getNextVersion(flowJson?.version || '')
      }
    })
    .addCase(handleDeleteStepsThunk.fulfilled, (state, action) => {
      handleFulfilled(state, action.payload)
    })
    .addCase(handleDeleteStepsThunk.rejected, (state, action) => {
      const scriptId = action.urlData?.scriptId
      state[scriptId].isLoading = false
      const draftFlowJson = cloneDeep(state[scriptId]?.draftedFlowJson)
      state[scriptId].flowJson = { ...draftFlowJson }
    })

    .addCase(createOrUpdateTriggerThunk.fulfilled, (state, action) => {
      handleFulfilled(state, action?.payload)
    })
    .addCase(createOrUpdateTriggerThunk.pending, (state, action) => {
      state[action?.urlData?.scriptId].isLoading = false
    })
    .addCase(createOrUpdateTriggerThunk.rejected, (state, action) => {
      state[action?.urlData?.scriptId].isLoading = false
    })
}

const handleFulfilled = (state, payload, urlScriptId) => {
  try {
    const { json_script, published_json_script, published_used_variables, id, draft_json_script, metadata } = payload
    const scriptId = id || urlScriptId
    const refJsonScript = cloneDeep(json_script || draft_json_script)
    const refPublishedJsonScript = cloneDeep(published_json_script)
    const refPublishedUsedVariable = cloneDeep(published_used_variables)
    const refMetaData = cloneDeep(metadata)
    // state[scriptId].flowJson = { ...refJsonScript, cron: refJsonScript?.cron || flowJsonSampleobj.flowJson.cron }
    state[scriptId] = { ...state?.[scriptId], flowJson: { ...refJsonScript, cron: refJsonScript?.cron || flowJsonSampleobj.flowJson.cron } }
    state[scriptId].draftedFlowJson = { ...refJsonScript, cron: refJsonScript?.cron || flowJsonSampleobj.flowJson.cron }
    state[scriptId].publishedFlowJson = {
      ...refPublishedJsonScript,
      cron: refPublishedJsonScript?.cron || flowJsonSampleobj.publishedFlowJson.cron
    }
    state[scriptId].metaData = refMetaData
    state[scriptId].stepOrder = getStepOrderArray(refJsonScript?.order)
    if (refPublishedUsedVariable) {
      state[scriptId].publishUsedVariables = refPublishedUsedVariable
    }
    state[scriptId].isLoading = false
  } catch (error) {
    errorToast('Json is not Valid')
    console.error(error)
  }
}
