import { SliceCaseReducers, createSlice } from '@reduxjs/toolkit'

// // reducer imports
import { $StepsReduxTypeV3 } from '../../types/reduxCore.ts'
import { initialState, reducers, extraReducers } from './stepReducerV3.ts'

const stepsDataV3Slice = createSlice<$StepsReduxTypeV3, SliceCaseReducers<$StepsReduxTypeV3>, 'stepsDataV3'>({
  name: 'stepsDataV3',
  initialState,
  reducers,
  extraReducers
})

export const {
  setKeyValueInstance,
  setDryRunResponseForStep,
  addNewStepToStepData,
  addDryRunSliderData,
  deleteStepFromStepData,
  setHeadersFieldV2,
  deleteScriptFromStepReducer,
  setApiInStep,
  // ======= PLUGIN =======
  resetCurrentSelectedPlugin,
  updateSingleInputJson,
  setPluginInputJson,
  startVerifyAuth,
  successVerifyAuth,
  startIsVerify,
  successIsVerify,
  setSelectedActionDetails,
  setPayloadInPluginInfoAirtable,
  // INPUT FIELDS OPERATION
  setValueOnKey,
  setAuthData,
  setAuthFieldsStart,
  setSelectedCreated,
  updateRequiredFieldsValue,
  startDryRunSource,
  setPluginData,
  deleteDataInInputJson,
  deleteKeyInBlocksOfInputJson,
  removeDataFromStepsAndBlocks,

  // save
  startDryRun,
  startSave,
  successDryRun,
  successSave,
  updateOptinalFieldVisibility,
  setIsVerify,
  removeOptinalFieldByKeyVisibility,
  removeAllOptinalFieldVisibility,
  errorSourceDryRun,
  setDynamicCheckbox,
  setDynamicInputRequired,
  setHasUnsavedCode,
  updateRequiredFieldList,
  updateFlags,
  clearInputJson
} = stepsDataV3Slice.actions

export default stepsDataV3Slice.reducer
