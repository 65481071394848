// src/components/SocialShare.js
import React from 'react'
import { Box, Typography } from '@mui/material'
import ShareButton from './shareButton.tsx'

function SocialShare() {
  const { url, title, description, hashtags } = {
    url: 'https://viasocket.com', // Replace with your URL
    title: 'Automate Your Day with viaSocket',
    description:
      "Thrilled to share my experience with viaSocket!  This powerhouse of automation has completely redefined how I work, connecting apps and automating workflows effortlessly. If you're ready to boost productivity and save time, don’t wait—try @viaSocket today!",

    hashtags: ['automation', 'embeding', 'Sharing']
  }

  return (
    <Box className='w-100'>
      <Typography variant='h6' className='px-3 pb-2'>
        {' '}
        Help us grow{' '}
      </Typography>
      <Box className='flex' width='100%' spacing={2}>
        <ShareButton platform='Facebook' url={url} title={title} />
        <ShareButton platform='Linkedin' url={url} title={title} description={description} />
        <ShareButton platform='X' url={url} title={title} description={description} hashtags={hashtags} />
        <ShareButton platform='Whatsapp' url={url} title={title} description={description} />
      </Box>
    </Box>
  )
}

export default SocialShare
