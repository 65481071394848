import { ALERTTYPES } from '../../enums'
import axios from '../../interceptor/interceptor'
import { DH_FLOW_FILTERS } from '../../pages/developerHub/constants/developerHubConstants.ts'
import { ActionFields } from '../../types/DHAction.ts'
import { AuthFieldsType } from '../../types/DHAuthentication.ts'
import { PluginFields } from '../../types/DHPlugin.ts'
import { sendAlert } from '../../utils/alertUtility.ts'
import { getInfoParamtersFromUrl } from '../../utils/utilities'

const URL = process.env.REACT_APP_API_BASE_URL

/**
 *
 * @param data
 * @param data.filter
 * @param data.identifier
 * @param tableName
 */
export async function dhGetApi(data: { filter: string; identifier: string }, tableName: string) {
  try {
    const orgId = getInfoParamtersFromUrl()?.orgId
    const response = await axios.get(`${URL}/developers/${orgId}/get/${tableName}?identifier=${data?.identifier}&filter=${data?.filter}`)
    return { isSuccess: response.data?.success, data: response?.data?.data }
  } catch (error) {
    return { isSuccess: false, data: [] }
  }
}

/**
 *
 * @param data
 * @param data.filter
 * @param data.field
 * @param data.identifier
 * @param tableName
 */
export async function dhGetParticularFieldApi(data: { filter: string; field: string; identifier: string }, tableName: string) {
  try {
    const orgId = getInfoParamtersFromUrl()?.orgId
    const response = await axios.get(
      `${URL}/developers/${orgId}/get/${tableName}?identifier=${data?.identifier}&filter=${data?.filter}&fields=${data?.field}`
    )
    return { isSuccess: response.data?.success, data: response?.data?.data }
  } catch (error) {
    return { isSuccess: false, data: [] }
  }
}

/**
 *
 * @param data
 * @param tableName
 */
export async function dhCreateApi(data: any, tableName: string) {
  const orgId = getInfoParamtersFromUrl()?.orgId
  try {
    const response = await axios.post(`${URL}/developers/${orgId}/create/${tableName}`, data)
    return {
      isSuccess: response.data?.success,
      data: response?.data?.data?.actionData?.[0],
      actionVersionData: response?.data?.data?.actionVersionData
    }
  } catch (error) {
    return { isSuccess: false, data: [] }
  }
}

/**
 *
 * @param data
 * @param data.filter
 * @param data.dataToUpdate
 * @param data.identifier
 * @param tableName
 */
export async function dhUpdateApi(
  data: { filter: string; dataToUpdate: PluginFields | AuthFieldsType | ActionFields; identifier: string },
  tableName: string
) {
  try {
    const orgId = getInfoParamtersFromUrl()?.orgId
    const response = await axios.put(
      `${URL}/developers/${orgId}/update/${tableName}?identifier=${data?.identifier}&filter=${data?.filter}`,
      {
        ...data?.dataToUpdate
      }
    )
    return { isSuccess: response.data?.success, data: response?.data?.data[0], errorMessage: response?.data?.data?.message }
  } catch (error) {
    return { isSuccess: false, data: [], errorMessage: error?.response?.data?.data }
  }
}

/**
 *
 * @param pluginId
 */
export async function dhGetActionIdAndAuthIdCountApi(pluginId: string) {
  try {
    const orgId = getInfoParamtersFromUrl()?.orgId
    const response = await axios.get(`${URL}/developers/${orgId}/GetUsedInCountForAuth?pluginId=${pluginId}`)
    return { isSuccess: response.data?.success, data: response?.data?.data }
  } catch (error) {
    return { isSuccess: false, data: [] }
  }
}

/**
 *
 * @param actionId
 */
export async function dhGetActionVersionCount(actionId: string) {
  try {
    const orgId = getInfoParamtersFromUrl()?.orgId
    const response = await axios.get(`${URL}/developers/${orgId}/GetActionVersionCount?actionId=${actionId}`)
    if (!response?.data) {
      return '0'
    }
    return response?.data?.data
  } catch (error) {
    sendAlert(actionId, 'DH', 'Error while getting action version count')
    return '0'
  }
}

/**
 * Used to send rtlayer event with data on a particulate channel
 * @param data : data to send
 * @param channelId : channel id to send data
 */
export async function SendRtlayerEventApi(data: any, channelId: string) {
  try {
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/utility/rtlayer`, {
      data: data,
      channelId: channelId
    })
  } catch (error) {
    sendAlert(error, ALERTTYPES.FRONTEND, 'Alert Sending rtlyaer event for Authentication window')
  }
}

/**
 * Retrieves analytics data for a plugin.
 *
 * @param {string} queryString - The query string to pass to the API.
 * @returns {Promise<{isSuccess: boolean, data: any[]}>} - A promise that resolves to an object containing a boolean indicating success and an array of analytics data.
 */
export async function getDHAnalyticsData(queryString: string) {
  try {
    const orgId = getInfoParamtersFromUrl()?.orgId
    const response = await axios.get(
      `${URL}/developers/${orgId}/analytics/plugins?${queryString}&filter=${DH_FLOW_FILTERS.GET_PLUGIN_DETAIL_START}`
    )
    return { isSuccess: response.data?.success, data: response?.data?.data }
  } catch (error) {
    return { isSuccess: false, data: [] }
  }
}

/**
 * Retrieves error status code data for a action verison id.
 *
 * @param {string} queryString - The query string to pass to the API.
 * @returns {Promise<{isSuccess: boolean, data: any[]}>} - A promise that resolves to an object containing a boolean indicating success and an array of analytics data.
 */
export async function getDHAErrorCodeMappingApi(queryString: string) {
  try {
    const orgId = getInfoParamtersFromUrl()?.orgId
    const response = await axios.get(
      `${URL}/developers/${orgId}/analytics/error-codes/action_version?${queryString}&filter=${DH_FLOW_FILTERS.GET_ACTION_VERSION_START}`
    )
    return { isSuccess: response.data?.success, data: response?.data?.data }
  } catch (error) {
    return { isSuccess: false, data: [] }
  }
}

/**
 * Retrieves error status code data for a action verison id.
 *
 * @param {string} queryString - The query string to pass to the API.
 * @returns {Promise<{isSuccess: boolean, data: any[]}>} - A promise that resolves to an object containing a boolean indicating success and an array of analytics data.
 */
export async function getLogsPayloadByStatusCodeApi(queryString: string) {
  try {
    const orgId = getInfoParamtersFromUrl()?.orgId
    const response = await axios.get(
      `${URL}/developers/${orgId}/analytics/logs/action_version?${queryString}&filter=${DH_FLOW_FILTERS.GET_ACTION_VERSION_START}`
    )
    return { isSuccess: response.data?.success, data: response?.data?.data }
  } catch (error) {
    return { isSuccess: false, data: [] }
  }
}

/**
 * Force Update users actions to latest versions.
 *
 * @param {{[key:string]:{actionVersionId:string,perform:string}}} data - The Data to update.
 * @returns {Promise<{isSuccess: boolean, data: any[]}>} - A promise that resolves to an object containing a boolean indicating success and an array of analytics data.
 */

export async function forceUpdateDataInFunctionTable(data: { [key: string]: { actionVersionId: string; perform: string } }) {
  // eslint-disable-next-line no-useless-catch
  try {
    const orgId = getInfoParamtersFromUrl()?.orgId
    const response = await axios.post(`${URL}/developers/${orgId}/forceUpdateActionVersions`, data)
    return { isSuccess: response.data?.success, data: response?.data?.data }
  } catch (error) {
    throw error
  }
}

/**
 * Get Bridge versions for crowd source chat bot
 *
 * @param {{[key:string]:{actionVersionId:string,perform:string}}} data - The Data to update.
 * @returns {Promise<{isSuccess: boolean, data: any[]}>} - A promise that resolves to an object containing a boolean indicating success and an array of analytics data.
 */
export async function getBridgeVersionsForCrowdSourceApi() {
  const resposne = await axios.get('https://flow.sokt.io/func/scrib3U1J0s8')
  return resposne.data
}

/**
 * Approve or Reject L2 verification of actions
 *
 * @param {{[key:string]:{actionVersionId:string,perform:string}}} data - The Data to update.
 * @returns {Promise<{isSuccess: boolean, data: any[]}>} - A promise that resolves to an object containing a boolean indicating success and an array of analytics data.
 */

export async function updateVerificationStatusAPI(payload: { tableName: 'action_version' | 'plugins'; data: { [key: string]: string[] } }) {
  // eslint-disable-next-line no-useless-catch
  try {
    const orgId = getInfoParamtersFromUrl()?.orgId
    const response = await axios.post(`${URL}/developers/${orgId}/pluginVerification`, payload)
    return { isSuccess: response.data?.success, data: response?.data?.data }
  } catch (error) {
    throw error
  }
}
