// import {saveAuth } from './authThunk';
import { SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit'
import { AppInfoReduxType } from '../../types/AppInfoState.ts'
import actionType from '../../types/utility.ts'
import { Tabnames } from '../../enums'

export const initialState: AppInfoReduxType = {
  currentOrgId: '',
  isDryRunResizer: false,
  isScriptDryRunInProcess: false,
  currentProjectId: '',
  currentScriptId: '',
  currentStepType: '',
  currentSlugName: '',
  currentStepId: '', // this is the DB id where step data is stored it is used to get the function data
  isScriptDrawerOpen: false,
  isStepDrawerOpen: false,
  isModalOpen: false,
  promptValueForAskAiNavbar: '',
  modalType: '',
  dataForModel: null,
  showFullScreen: false,
  isLogSliderOpen: false,
  isProjectSliderOpen: true,
  mode: 'flow',
  isAiSliderOpen: false,
  isPluginDashboard: true,
  isDynamicSectionOpen: false,
  isEmbedProjectSliderOpen: false,
  isTemplateSliderOpen: false,
  currentTab: Tabnames.DRAFT,
  isAlertOnPublishSliderOpen: false,
  isPluginActionSelectOpen: '',
  duplicateFlowId: '',
  globalSearchBarValue: '',
  serviceSliderOpen: false,
  localConfiguration: false,
  compareSliderOpen: false,
  librarySupported: [],
  chatbottoken: '',
  isFeedbackSubmitted: false,
  flowByAiWhenCreateFlowText: ''
}

export const reducers: ValidateSliceCaseReducers<AppInfoReduxType, SliceCaseReducers<AppInfoReduxType>> = {
  togglePluginDashboard(state, action) {
    state.isPluginDashboard = action.payload || false
  },
  togglePluginActionSelect(state, action) {
    state.isPluginActionSelectOpen = action.payload || ''
  },
  openAiSlider(state) {
    state.isAlertOnPublishSliderOpen = false
    state.isAiSliderOpen = true
  },
  closeAiSlider(state) {
    state.isAiSliderOpen = false
  },
  toggleProjectSlider(state) {
    state.isProjectSliderOpen = !state.isProjectSliderOpen
  },
  toggleLogsSlider(state, action) {
    state.isLogSliderOpen = action.payload
  },
  openStepSlider(state, action) {
    state.currentStepId = action.payload.currentStepId || ''
    state.currentSlugName = action.payload.currentSlugName || ''
    state.currentStepType = action.payload.currentStepType || ''
    state.isScriptDrawerOpen = true
  },
  closeStepSlider(state) {
    state.showFullScreen = false
  },
  setIsDryRunResizerOpen(state, action: actionType<boolean>) {
    state.isDryRunResizer = action.payload
  },
  setIsDryRunInProcessForScript(state, action: actionType<boolean>) {
    state.isScriptDryRunInProcess = action.payload
  },
  setIsScriptDrawerOpen(state, action: actionType<boolean>) {
    state.isScriptDrawerOpen = action.payload
    if (!action.payload) state.isProjectSliderOpen = false
  },
  setIsAlertOnPublishSliderOpen(state, action: actionType<boolean>) {
    state.isAlertOnPublishSliderOpen = action.payload
    if (action.payload) state.isAiSliderOpen = false
  },
  setShowFullScreen(state, action: actionType<boolean>) {
    state.showFullScreen = action.payload
  },
  openModalRedux(state, action: actionType<{ modalType: string; dataForModel?: any }>) {
    state.isModalOpen = true
    state.modalType = action.payload.modalType
    if (action.payload.dataForModel) {
      state.dataForModel = action.payload.dataForModel
    }
  },
  closeModalRedux(state) {
    state.isModalOpen = false
    state.modalType = ''
  },
  clearAppInfo() {
    return { ...initialState }
  },
  updateAppInfoState(state, action: actionType<{ [key: string]: any }>) {
    if (action.payload?.currentStepType) {
      action.payload.showFullScreen = false
    }

    return { ...state, ...action.payload }
  },
  setSupportedLibraries(state, action: actionType<{ [key: string]: any }>) {
    return { ...state, librarySupported: action.payload }
  },
  toggleisFeedbackSubmitted(state, action: actionType<{ [key: boolean] }>) {
    state.isFeedbackSubmitted = action.payload
  }
}
