import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Typography, Box, Container } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import CustomModal from '../../components/customModal/customModal'
import SelectOrgModal from './selectOrgModal.tsx'
import { ServicePageSkeleton } from '../../components/skeleton/servicePageSkeleton'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import PluginSearchBar from '../../components/projectdashboard/PluginSearchBar.tsx'

export const navigateToUrl = (object, navigate, isBasic = false, isSettingPage = false, params = '') => {
  let url =
    isBasic && object?.preferedauthversion
      ? `/auth/service/${object?.rowid}/auth/${object?.preferedauthversion}`
      : `/auth/service/${object?.rowid}`
  const { search } = window.location
  if (search) {
    url += search
  }

  if (isSettingPage) {
    const stateParams = new URLSearchParams({
      info: JSON.stringify({
        isBasictype: String(isBasic),
        origin: 'setting'
      }),
      backButton: 'false'
    }).toString()
    if (url[url.length - 1] !== '?') url += '?'
    window.open(`${url}${params}&${stateParams}`, 'PopupPage', 'height=600,width=800')
  } else {
    navigate(url, {
      state: {
        info: {
          isBasictype: isBasic,
          origin: ''
        },
        backButton: true,
        pluginDetails: object,
        isSettingPage: isSettingPage
      }
    })
  }
}
function ServicePage({ orgid }) {
  const navigate = useNavigate()
  const [servicesData, setServicesData] = useState({
    isLoading: false,
    pluginsArray: []
  })
  const [showOrgPopup, setShowOrgPopup] = useState(!orgid)

  useEffect(() => {
    setShowOrgPopup(!orgid)
  }, [orgid])

  const setPluginDetails = async (object) => {
    if (object?.preferedauthtype === 'Basic') {
      navigateToUrl(object, navigate, true)
    } else navigateToUrl(object, navigate, false)
  }

  return (
    <div className='p-4 w-100'>
      {orgid && (
        <Container maxWidth='md' className='flex-col gap-3'>
          <Box className='flex-col gap-5'>
            <Typography variant='h4'>Add New Connection </Typography>
            <PluginSearchBar
              autoFocus
              placeholder='Search services by name, description or domain '
              getServices={({ servicesArray, isLoading: loading }) => {
                setServicesData({
                  isLoading: loading,
                  pluginsArray: servicesArray
                })
              }}
            />
          </Box>
          <Box className='flex flex-wrap w-100 h-100'>
            {servicesData?.pluginsArray?.length === 0 && !servicesData?.isLoading && <Typography variant='h3'>No data found</Typography>}
            {servicesData?.isLoading && (
              <Box className='w-100 h-100'>
                <ServicePageSkeleton />
              </Box>
            )}
            {servicesData?.pluginsArray?.map((object) => (
              <Box
                className='p-2 flex-center cursor-pointer authdatabox mb-3 mr-3'
                key={object.rowid}
                onClick={() => setPluginDetails(object)}
              >
                <Avatar alt={object?.name} src={object?.iconurl} variant='extrasmall' className='mr-3' />
                <Typography className='mr-3'>{object?.name}</Typography>
                <ArrowForwardIosIcon className='extra-small-icon' />
              </Box>
            ))}
          </Box>
        </Container>
      )}
      <CustomModal openModal={showOrgPopup}>
        <SelectOrgModal setShowOrgPopup={setShowOrgPopup} />
      </CustomModal>
    </div>
  )
}

export default React.memo(addUrlDataHoc(React.memo(ServicePage), ['orgid']))
