import React, { useEffect, useState } from 'react'
import { getFrillToken } from '../../api'
import { LoadingStateComponent } from '../discord-login/discordLoginPage'

function FrillLogin() {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading('loading')
    ;(async () => {
      try {
        const { redirectUrl } = await getFrillToken()
        setLoading('false')
        if (redirectUrl) window.location.replace(redirectUrl)
      } catch (error) {
        setLoading('error')
      }
    })()
  }, [])

  return <LoadingStateComponent loading={loading} />
}

export default FrillLogin
