export function getLocalTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

const timezoneData = [
  { identifier: 'Africa/Abidjan', offSet: '+00:00' },
  { identifier: 'Africa/Accra', offSet: '+00:00' },
  { identifier: 'Africa/Addis_Ababa', offSet: '+03:00' },
  { identifier: 'Africa/Algiers', offSet: '+01:00' },
  { identifier: 'Africa/Asmara', offSet: '+03:00' },
  { identifier: 'Africa/Asmera', offSet: '+03:00' },
  { identifier: 'Africa/Bamako', offSet: '+00:00' },
  { identifier: 'Africa/Bangui', offSet: '+01:00' },
  { identifier: 'Africa/Banjul', offSet: '+00:00' },
  { identifier: 'Africa/Bissau', offSet: '+00:00' },
  { identifier: 'Africa/Blantyre', offSet: '+02:00' },
  { identifier: 'Africa/Brazzaville', offSet: '+01:00' },
  { identifier: 'Africa/Bujumbura', offSet: '+02:00' },
  { identifier: 'Africa/Cairo', offSet: '+02:00' },
  { identifier: 'Africa/Casablanca', offSet: '+01:00' },
  { identifier: 'Africa/Ceuta', offSet: '+01:00' },
  { identifier: 'Africa/Conakry', offSet: '+00:00' },
  { identifier: 'Africa/Dakar', offSet: '+00:00' },
  { identifier: 'Africa/Dar_es_Salaam', offSet: '+03:00' },
  { identifier: 'Africa/Djibouti', offSet: '+03:00' },
  { identifier: 'Africa/Douala', offSet: '+01:00' },
  { identifier: 'Africa/El_Aaiun', offSet: '+01:00' },
  { identifier: 'Africa/Freetown', offSet: '+00:00' },
  { identifier: 'Africa/Gaborone', offSet: '+02:00' },
  { identifier: 'Africa/Harare', offSet: '+02:00' },
  { identifier: 'Africa/Johannesburg', offSet: '+02:00' },
  { identifier: 'Africa/Juba', offSet: '+02:00' },
  { identifier: 'Africa/Kampala', offSet: '+03:00' },
  { identifier: 'Africa/Khartoum', offSet: '+02:00' },
  { identifier: 'Africa/Kigali', offSet: '+02:00' },
  { identifier: 'Africa/Kinshasa', offSet: '+01:00' },
  { identifier: 'Africa/Lagos', offSet: '+01:00' },
  { identifier: 'Africa/Libreville', offSet: '+01:00' },
  { identifier: 'Africa/Lome', offSet: '+00:00' },
  { identifier: 'Africa/Luanda', offSet: '+01:00' },
  { identifier: 'Africa/Lubumbashi', offSet: '+02:00' },
  { identifier: 'Africa/Lusaka', offSet: '+02:00' },
  { identifier: 'Africa/Malabo', offSet: '+01:00' },
  { identifier: 'Africa/Maputo', offSet: '+02:00' },
  { identifier: 'Africa/Maseru', offSet: '+02:00' },
  { identifier: 'Africa/Mbabane', offSet: '+02:00' },
  { identifier: 'Africa/Mogadishu', offSet: '+03:00' },
  { identifier: 'Africa/Monrovia', offSet: '+00:00' },
  { identifier: 'Africa/Nairobi', offSet: '+03:00' },
  { identifier: 'Africa/Ndjamena', offSet: '+01:00' },
  { identifier: 'Africa/Niamey', offSet: '+01:00' },
  { identifier: 'Africa/Nouakchott', offSet: '+00:00' },
  { identifier: 'Africa/Ouagadougou', offSet: '+00:00' },
  { identifier: 'Africa/Porto-Novo', offSet: '+01:00' },
  { identifier: 'Africa/Sao_Tome', offSet: '+00:00' },
  { identifier: 'Africa/Timbuktu', offSet: '+00:00' },
  { identifier: 'Africa/Tripoli', offSet: '+02:00' },
  { identifier: 'Africa/Tunis', offSet: '+01:00' },
  { identifier: 'Africa/Windhoek', offSet: '+02:00' },
  { identifier: 'America/Adak', offSet: '-10:00' },
  { identifier: 'America/Anchorage', offSet: '-09:00' },
  { identifier: 'America/Anguilla', offSet: '-04:00' },
  { identifier: 'America/Antigua', offSet: '-04:00' },
  { identifier: 'America/Araguaina', offSet: '-03:00' },
  { identifier: 'America/Argentina/Buenos_Aires', offSet: '-03:00' },
  { identifier: 'America/Argentina/Catamarca', offSet: '-03:00' },
  { identifier: 'America/Argentina/ComodRivadavia', offSet: '-03:00' },
  { identifier: 'America/Argentina/Cordoba', offSet: '-03:00' },
  { identifier: 'America/Argentina/Jujuy', offSet: '-03:00' },
  { identifier: 'America/Argentina/La_Rioja', offSet: '-03:00' },
  { identifier: 'America/Argentina/Mendoza', offSet: '-03:00' },
  { identifier: 'America/Argentina/Rio_Gallegos', offSet: '-03:00' },
  { identifier: 'America/Argentina/Salta', offSet: '-03:00' },
  { identifier: 'America/Argentina/San_Juan', offSet: '-03:00' },
  { identifier: 'America/Argentina/San_Luis', offSet: '-03:00' },
  { identifier: 'America/Argentina/Tucuman', offSet: '-03:00' },
  { identifier: 'America/Argentina/Ushuaia', offSet: '-03:00' },
  { identifier: 'America/Aruba', offSet: '-04:00' },
  { identifier: 'America/Asuncion', offSet: '-03:00' },
  { identifier: 'America/Atikokan', offSet: '-05:00' },
  { identifier: 'America/Atka', offSet: '-10:00' },
  { identifier: 'America/Bahia', offSet: '-03:00' },
  { identifier: 'America/Bahia_Banderas', offSet: '-06:00' },
  { identifier: 'America/Barbados', offSet: '-04:00' },
  { identifier: 'America/Belem', offSet: '-03:00' },
  { identifier: 'America/Belize', offSet: '-06:00' },
  { identifier: 'America/Blanc-Sablon', offSet: '-04:00' },
  { identifier: 'America/Boa_Vista', offSet: '-04:00' },
  { identifier: 'America/Bogota', offSet: '-05:00' },
  { identifier: 'America/Boise', offSet: '-07:00' },
  { identifier: 'America/Buenos_Aires', offSet: '-03:00' },
  { identifier: 'America/Cambridge_Bay', offSet: '-07:00' },
  { identifier: 'America/Campo_Grande', offSet: '-04:00' },
  { identifier: 'America/Cancun', offSet: '-05:00' },
  { identifier: 'America/Caracas', offSet: '-04:00' },
  { identifier: 'America/Catamarca', offSet: '-03:00' },
  { identifier: 'America/Cayenne', offSet: '-03:00' },
  { identifier: 'America/Cayman', offSet: '-05:00' },
  { identifier: 'America/Chicago', offSet: '-06:00' },
  { identifier: 'America/Chihuahua', offSet: '-06:00' },
  { identifier: 'America/Ciudad_Juarez', offSet: '-07:00' },
  { identifier: 'America/Coral_Harbour', offSet: '-05:00' },
  { identifier: 'America/Cordoba', offSet: '-03:00' },
  { identifier: 'America/Costa_Rica', offSet: '-06:00' },
  { identifier: 'America/Creston', offSet: '-07:00' },
  { identifier: 'America/Cuiaba', offSet: '-04:00' },
  { identifier: 'America/Curacao', offSet: '-04:00' },
  { identifier: 'America/Danmarkshavn', offSet: '+00:00' },
  { identifier: 'America/Dawson', offSet: '-07:00' },
  { identifier: 'America/Dawson_Creek', offSet: '-07:00' },
  { identifier: 'America/Denver', offSet: '-07:00' },
  { identifier: 'America/Detroit', offSet: '-05:00' },
  { identifier: 'America/Dominica', offSet: '-04:00' },
  { identifier: 'America/Edmonton', offSet: '-07:00' },
  { identifier: 'America/Eirunepe', offSet: '-05:00' },
  { identifier: 'America/El_Salvador', offSet: '-06:00' },
  { identifier: 'America/Ensenada', offSet: '-08:00' },
  { identifier: 'America/Fort_Nelson', offSet: '-07:00' },
  { identifier: 'America/Fort_Wayne', offSet: '-05:00' },
  { identifier: 'America/Fortaleza', offSet: '-03:00' },
  { identifier: 'America/Glace_Bay', offSet: '-04:00' },
  { identifier: 'America/Godthab', offSet: '-02:00' },
  { identifier: 'America/Goose_Bay', offSet: '-04:00' },
  { identifier: 'America/Grand_Turk', offSet: '-05:00' },
  { identifier: 'America/Grenada', offSet: '-04:00' },
  { identifier: 'America/Guadeloupe', offSet: '-04:00' },
  { identifier: 'America/Guatemala', offSet: '-06:00' },
  { identifier: 'America/Guayaquil', offSet: '-05:00' },
  { identifier: 'America/Guyana', offSet: '-04:00' },
  { identifier: 'America/Halifax', offSet: '-04:00' },
  { identifier: 'America/Havana', offSet: '-05:00' },
  { identifier: 'America/Hermosillo', offSet: '-07:00' },
  { identifier: 'America/Indiana/Indianapolis', offSet: '-05:00' },
  { identifier: 'America/Indiana/Knox', offSet: '-06:00' },
  { identifier: 'America/Indiana/Marengo', offSet: '-05:00' },
  { identifier: 'America/Indiana/Petersburg', offSet: '-05:00' },
  { identifier: 'America/Indiana/Tell_City', offSet: '-06:00' },
  { identifier: 'America/Indiana/Vevay', offSet: '-05:00' },
  { identifier: 'America/Indiana/Vincennes', offSet: '-05:00' },
  { identifier: 'America/Indiana/Winamac', offSet: '-05:00' },
  { identifier: 'America/Indianapolis', offSet: '-05:00' },
  { identifier: 'America/Inuvik', offSet: '-07:00' },
  { identifier: 'America/Iqaluit', offSet: '-05:00' },
  { identifier: 'America/Jamaica', offSet: '-05:00' },
  { identifier: 'America/Jujuy', offSet: '-03:00' },
  { identifier: 'America/Juneau', offSet: '-09:00' },
  { identifier: 'America/Kentucky/Louisville', offSet: '-05:00' },
  { identifier: 'America/Kentucky/Monticello', offSet: '-05:00' },
  { identifier: 'America/Knox_IN', offSet: '-06:00' },
  { identifier: 'America/Kralendijk', offSet: '-04:00' },
  { identifier: 'America/La_Paz', offSet: '-04:00' },
  { identifier: 'America/Lima', offSet: '-05:00' },
  { identifier: 'America/Los_Angeles', offSet: '-08:00' },
  { identifier: 'America/Louisville', offSet: '-05:00' },
  { identifier: 'America/Lower_Princes', offSet: '-04:00' },
  { identifier: 'America/Maceio', offSet: '-03:00' },
  { identifier: 'America/Managua', offSet: '-06:00' },
  { identifier: 'America/Manaus', offSet: '-04:00' },
  { identifier: 'America/Marigot', offSet: '-04:00' },
  { identifier: 'America/Martinique', offSet: '-04:00' },
  { identifier: 'America/Matamoros', offSet: '-06:00' },
  { identifier: 'America/Mazatlan', offSet: '-07:00' },
  { identifier: 'America/Mendoza', offSet: '-03:00' },
  { identifier: 'America/Menominee', offSet: '-06:00' },
  { identifier: 'America/Merida', offSet: '-06:00' },
  { identifier: 'America/Metlakatla', offSet: '-09:00' },
  { identifier: 'America/Mexico_City', offSet: '-06:00' },
  { identifier: 'America/Miquelon', offSet: '-03:00' },
  { identifier: 'America/Moncton', offSet: '-04:00' },
  { identifier: 'America/Monterrey', offSet: '-06:00' },
  { identifier: 'America/Montevideo', offSet: '-03:00' },
  { identifier: 'America/Montreal', offSet: '-05:00' },
  { identifier: 'America/Montserrat', offSet: '-04:00' },
  { identifier: 'America/Nassau', offSet: '-05:00' },
  { identifier: 'America/New_York', offSet: '-05:00' },
  { identifier: 'America/Nipigon', offSet: '-05:00' },
  { identifier: 'America/Nome', offSet: '-09:00' },
  { identifier: 'America/Noronha', offSet: '-02:00' },
  { identifier: 'America/North_Dakota/Beulah', offSet: '-06:00' },
  { identifier: 'America/North_Dakota/Center', offSet: '-06:00' },
  { identifier: 'America/North_Dakota/New_Salem', offSet: '-06:00' },
  { identifier: 'America/Nuuk', offSet: '-02:00' },
  { identifier: 'America/Ojinaga', offSet: '-06:00' },
  { identifier: 'America/Panama', offSet: '-05:00' },
  { identifier: 'America/Pangnirtung', offSet: '-05:00' },
  { identifier: 'America/Paramaribo', offSet: '-03:00' },
  { identifier: 'America/Phoenix', offSet: '-07:00' },
  { identifier: 'America/Port-au-Prince', offSet: '-05:00' },
  { identifier: 'America/Port_of_Spain', offSet: '-04:00' },
  { identifier: 'America/Porto_Acre', offSet: '-05:00' },
  { identifier: 'America/Porto_Velho', offSet: '-04:00' },
  { identifier: 'America/Puerto_Rico', offSet: '-04:00' },
  { identifier: 'America/Punta_Arenas', offSet: '-03:00' },
  { identifier: 'America/Rainy_River', offSet: '-06:00' },
  { identifier: 'America/Rankin_Inlet', offSet: '-06:00' },
  { identifier: 'America/Recife', offSet: '-03:00' },
  { identifier: 'America/Regina', offSet: '-06:00' },
  { identifier: 'America/Resolute', offSet: '-06:00' },
  { identifier: 'America/Rio_Branco', offSet: '-05:00' },
  { identifier: 'America/Rosario', offSet: '-03:00' },
  { identifier: 'America/Santa_Isabel', offSet: '-08:00' },
  { identifier: 'America/Santarem', offSet: '-03:00' },
  { identifier: 'America/Santiago', offSet: '-03:00' },
  { identifier: 'America/Santo_Domingo', offSet: '-04:00' },
  { identifier: 'America/Sao_Paulo', offSet: '-03:00' },
  { identifier: 'America/Scoresbysund', offSet: '-02:00' },
  { identifier: 'America/Shiprock', offSet: '-07:00' },
  { identifier: 'America/Sitka', offSet: '-09:00' },
  { identifier: 'America/St_Barthelemy', offSet: '-04:00' },
  { identifier: 'America/St_Kitts', offSet: '-04:00' },
  { identifier: 'America/St_Lucia', offSet: '-04:00' },
  { identifier: 'America/St_Thomas', offSet: '-04:00' },
  { identifier: 'America/St_Vincent', offSet: '-04:00' },
  { identifier: 'America/Swift_Current', offSet: '-06:00' },
  { identifier: 'America/Tegucigalpa', offSet: '-06:00' },
  { identifier: 'America/Thule', offSet: '-04:00' },
  { identifier: 'America/Thunder_Bay', offSet: '-05:00' },
  { identifier: 'America/Tijuana', offSet: '-08:00' },
  { identifier: 'America/Toronto', offSet: '-05:00' },
  { identifier: 'America/Tortola', offSet: '-04:00' },
  { identifier: 'America/Vancouver', offSet: '-08:00' },
  { identifier: 'America/Virgin', offSet: '-04:00' },
  { identifier: 'America/Whitehorse', offSet: '-07:00' },
  { identifier: 'America/Winnipeg', offSet: '-06:00' },
  { identifier: 'America/Yakutat', offSet: '-09:00' },
  { identifier: 'America/Yellowknife', offSet: '-07:00' },
  { identifier: 'Antarctica/Casey', offSet: '+08:00' },
  { identifier: 'Antarctica/Davis', offSet: '+07:00' },
  { identifier: 'Antarctica/DumontDUrville', offSet: '+10:00' },
  { identifier: 'Antarctica/Macquarie', offSet: '+11:00' },
  { identifier: 'Antarctica/Mawson', offSet: '+05:00' },
  { identifier: 'Antarctica/McMurdo', offSet: '+13:00' },
  { identifier: 'Antarctica/Palmer', offSet: '-03:00' },
  { identifier: 'Antarctica/Rothera', offSet: '-03:00' },
  { identifier: 'Antarctica/South_Pole', offSet: '+13:00' },
  { identifier: 'Antarctica/Syowa', offSet: '+03:00' },
  { identifier: 'Antarctica/Troll', offSet: '+00:00' },
  { identifier: 'Antarctica/Vostok', offSet: '+05:00' },
  { identifier: 'Arctic/Longyearbyen', offSet: '+01:00' },
  { identifier: 'Asia/Aden', offSet: '+03:00' },
  { identifier: 'Asia/Almaty', offSet: '+05:00' },
  { identifier: 'Asia/Amman', offSet: '+03:00' },
  { identifier: 'Asia/Anadyr', offSet: '+12:00' },
  { identifier: 'Asia/Aqtau', offSet: '+05:00' },
  { identifier: 'Asia/Aqtobe', offSet: '+05:00' },
  { identifier: 'Asia/Ashgabat', offSet: '+05:00' },
  { identifier: 'Asia/Ashkhabad', offSet: '+05:00' },
  { identifier: 'Asia/Atyrau', offSet: '+05:00' },
  { identifier: 'Asia/Baghdad', offSet: '+03:00' },
  { identifier: 'Asia/Bahrain', offSet: '+03:00' },
  { identifier: 'Asia/Baku', offSet: '+04:00' },
  { identifier: 'Asia/Bangkok', offSet: '+07:00' },
  { identifier: 'Asia/Barnaul', offSet: '+07:00' },
  { identifier: 'Asia/Beirut', offSet: '+02:00' },
  { identifier: 'Asia/Bishkek', offSet: '+06:00' },
  { identifier: 'Asia/Brunei', offSet: '+08:00' },
  { identifier: 'Asia/Calcutta', offSet: '+05:30' },
  { identifier: 'Asia/Chita', offSet: '+09:00' },
  { identifier: 'Asia/Choibalsan', offSet: '+08:00' },
  { identifier: 'Asia/Chongqing', offSet: '+08:00' },
  { identifier: 'Asia/Chungking', offSet: '+08:00' },
  { identifier: 'Asia/Colombo', offSet: '+05:30' },
  { identifier: 'Asia/Dacca', offSet: '+06:00' },
  { identifier: 'Asia/Damascus', offSet: '+03:00' },
  { identifier: 'Asia/Dhaka', offSet: '+06:00' },
  { identifier: 'Asia/Dili', offSet: '+09:00' },
  { identifier: 'Asia/Dubai', offSet: '+04:00' },
  { identifier: 'Asia/Dushanbe', offSet: '+05:00' },
  { identifier: 'Asia/Famagusta', offSet: '+02:00' },
  { identifier: 'Asia/Gaza', offSet: '+02:00' },
  { identifier: 'Asia/Harbin', offSet: '+08:00' },
  { identifier: 'Asia/Hebron', offSet: '+02:00' },
  { identifier: 'Asia/Ho_Chi_Minh', offSet: '+07:00' },
  { identifier: 'Asia/Hong_Kong', offSet: '+08:00' },
  { identifier: 'Asia/Hovd', offSet: '+07:00' },
  { identifier: 'Asia/Irkutsk', offSet: '+08:00' },
  { identifier: 'Asia/Istanbul', offSet: '+03:00' },
  { identifier: 'Asia/Jakarta', offSet: '+07:00' },
  { identifier: 'Asia/Jayapura', offSet: '+09:00' },
  { identifier: 'Asia/Jerusalem', offSet: '+02:00' },
  { identifier: 'Asia/Kabul', offSet: '+04:30' },
  { identifier: 'Asia/Kamchatka', offSet: '+12:00' },
  { identifier: 'Asia/Karachi', offSet: '+05:00' },
  { identifier: 'Asia/Kashgar', offSet: '+06:00' },
  { identifier: 'Asia/Kathmandu', offSet: '+05:45' },
  { identifier: 'Asia/Katmandu', offSet: '+05:45' },
  { identifier: 'Asia/Khandyga', offSet: '+09:00' },
  { identifier: 'Asia/Kolkata', offSet: '+05:30' },
  { identifier: 'Asia/Krasnoyarsk', offSet: '+07:00' },
  { identifier: 'Asia/Kuala_Lumpur', offSet: '+08:00' },
  { identifier: 'Asia/Kuching', offSet: '+08:00' },
  { identifier: 'Asia/Kuwait', offSet: '+03:00' },
  { identifier: 'Asia/Macao', offSet: '+08:00' },
  { identifier: 'Asia/Macau', offSet: '+08:00' },
  { identifier: 'Asia/Magadan', offSet: '+11:00' },
  { identifier: 'Asia/Makassar', offSet: '+08:00' },
  { identifier: 'Asia/Manila', offSet: '+08:00' },
  { identifier: 'Asia/Muscat', offSet: '+04:00' },
  { identifier: 'Asia/Nicosia', offSet: '+02:00' },
  { identifier: 'Asia/Novokuznetsk', offSet: '+07:00' },
  { identifier: 'Asia/Novosibirsk', offSet: '+07:00' },
  { identifier: 'Asia/Omsk', offSet: '+06:00' },
  { identifier: 'Asia/Oral', offSet: '+05:00' },
  { identifier: 'Asia/Phnom_Penh', offSet: '+07:00' },
  { identifier: 'Asia/Pontianak', offSet: '+07:00' },
  { identifier: 'Asia/Pyongyang', offSet: '+09:00' },
  { identifier: 'Asia/Qatar', offSet: '+03:00' },
  { identifier: 'Asia/Qostanay', offSet: '+05:00' },
  { identifier: 'Asia/Qyzylorda', offSet: '+05:00' },
  { identifier: 'Asia/Rangoon', offSet: '+06:30' },
  { identifier: 'Asia/Riyadh', offSet: '+03:00' },
  { identifier: 'Asia/Saigon', offSet: '+07:00' },
  { identifier: 'Asia/Sakhalin', offSet: '+11:00' },
  { identifier: 'Asia/Samarkand', offSet: '+05:00' },
  { identifier: 'Asia/Seoul', offSet: '+09:00' },
  { identifier: 'Asia/Shanghai', offSet: '+08:00' },
  { identifier: 'Asia/Singapore', offSet: '+08:00' },
  { identifier: 'Asia/Srednekolymsk', offSet: '+11:00' },
  { identifier: 'Asia/Taipei', offSet: '+08:00' },
  { identifier: 'Asia/Tashkent', offSet: '+05:00' },
  { identifier: 'Asia/Tbilisi', offSet: '+04:00' },
  { identifier: 'Asia/Tehran', offSet: '+03:30' },
  { identifier: 'Asia/Tel_Aviv', offSet: '+02:00' },
  { identifier: 'Asia/Thimbu', offSet: '+06:00' },
  { identifier: 'Asia/Thimphu', offSet: '+06:00' },
  { identifier: 'Asia/Tokyo', offSet: '+09:00' },
  { identifier: 'Asia/Tomsk', offSet: '+07:00' },
  { identifier: 'Asia/Ujung_Pandang', offSet: '+08:00' },
  { identifier: 'Asia/Ulaanbaatar', offSet: '+08:00' },
  { identifier: 'Asia/Ulan_Bator', offSet: '+08:00' },
  { identifier: 'Asia/Urumqi', offSet: '+06:00' },
  { identifier: 'Asia/Ust-Nera', offSet: '+10:00' },
  { identifier: 'Asia/Vientiane', offSet: '+07:00' },
  { identifier: 'Asia/Vladivostok', offSet: '+10:00' },
  { identifier: 'Asia/Yakutsk', offSet: '+09:00' },
  { identifier: 'Asia/Yangon', offSet: '+06:30' },
  { identifier: 'Asia/Yekaterinburg', offSet: '+05:00' },
  { identifier: 'Asia/Yerevan', offSet: '+04:00' },
  { identifier: 'Atlantic/Azores', offSet: '-01:00' },
  { identifier: 'Atlantic/Bermuda', offSet: '-04:00' },
  { identifier: 'Atlantic/Canary', offSet: '+00:00' },
  { identifier: 'Atlantic/Cape_Verde', offSet: '-01:00' },
  { identifier: 'Atlantic/Faeroe', offSet: '+00:00' },
  { identifier: 'Atlantic/Faroe', offSet: '+00:00' },
  { identifier: 'Atlantic/Jan_Mayen', offSet: '+01:00' },
  { identifier: 'Atlantic/Madeira', offSet: '+00:00' },
  { identifier: 'Atlantic/Reykjavik', offSet: '+00:00' },
  { identifier: 'Atlantic/South_Georgia', offSet: '-02:00' },
  { identifier: 'Atlantic/St_Helena', offSet: '+00:00' },
  { identifier: 'Atlantic/Stanley', offSet: '-03:00' },
  { identifier: 'Australia/ACT', offSet: '+11:00' },
  { identifier: 'Australia/Adelaide', offSet: '+10:30' },
  { identifier: 'Australia/Brisbane', offSet: '+10:00' },
  { identifier: 'Australia/Broken_Hill', offSet: '+10:30' },
  { identifier: 'Australia/Canberra', offSet: '+11:00' },
  { identifier: 'Australia/Currie', offSet: '+11:00' },
  { identifier: 'Australia/Darwin', offSet: '+09:30' },
  { identifier: 'Australia/Eucla', offSet: '+08:45' },
  { identifier: 'Australia/Hobart', offSet: '+11:00' },
  { identifier: 'Australia/LHI', offSet: '+11:00' },
  { identifier: 'Australia/Lindeman', offSet: '+10:00' },
  { identifier: 'Australia/Lord_Howe', offSet: '+11:00' },
  { identifier: 'Australia/Melbourne', offSet: '+11:00' },
  { identifier: 'Australia/NSW', offSet: '+11:00' },
  { identifier: 'Australia/North', offSet: '+09:30' },
  { identifier: 'Australia/Perth', offSet: '+08:00' },
  { identifier: 'Australia/Queensland', offSet: '+10:00' },
  { identifier: 'Australia/South', offSet: '+10:30' },
  { identifier: 'Australia/Sydney', offSet: '+11:00' },
  { identifier: 'Australia/Tasmania', offSet: '+11:00' },
  { identifier: 'Australia/Victoria', offSet: '+11:00' },
  { identifier: 'Australia/West', offSet: '+08:00' },
  { identifier: 'Australia/Yancowinna', offSet: '+10:30' },
  { identifier: 'Brazil/Acre', offSet: '-05:00' },
  { identifier: 'Brazil/DeNoronha', offSet: '-02:00' },
  { identifier: 'Brazil/East', offSet: '-03:00' },
  { identifier: 'Brazil/West', offSet: '-04:00' },
  { identifier: 'CET', offSet: '+01:00' },
  { identifier: 'CST6CDT', offSet: '-06:00' },
  { identifier: 'Canada/Atlantic', offSet: '-04:00' },
  { identifier: 'Canada/Central', offSet: '-06:00' },
  { identifier: 'Canada/Eastern', offSet: '-05:00' },
  { identifier: 'Canada/Mountain', offSet: '-07:00' },
  { identifier: 'Canada/Pacific', offSet: '-08:00' },
  { identifier: 'Canada/Saskatchewan', offSet: '-06:00' },
  { identifier: 'Canada/Yukon', offSet: '-07:00' },
  { identifier: 'Chile/Continental', offSet: '-03:00' },
  { identifier: 'Chile/EasterIsland', offSet: '-05:00' },
  { identifier: 'Cuba', offSet: '-05:00' },
  { identifier: 'EET', offSet: '+02:00' },
  { identifier: 'EST', offSet: '-05:00' },
  { identifier: 'EST5EDT', offSet: '-05:00' },
  { identifier: 'Egypt', offSet: '+02:00' },
  { identifier: 'Eire', offSet: '+00:00' },
  { identifier: 'Etc/GMT', offSet: '+00:00' },
  { identifier: 'Etc/GMT+0', offSet: '+00:00' },
  { identifier: 'Etc/GMT+1', offSet: '-01:00' },
  { identifier: 'Etc/GMT+10', offSet: '-10:00' },
  { identifier: 'Etc/GMT+11', offSet: '-11:00' },
  { identifier: 'Etc/GMT+12', offSet: '-12:00' },
  { identifier: 'Etc/GMT+2', offSet: '-02:00' },
  { identifier: 'Etc/GMT+3', offSet: '-03:00' },
  { identifier: 'Etc/GMT+4', offSet: '-04:00' },
  { identifier: 'Etc/GMT+5', offSet: '-05:00' },
  { identifier: 'Etc/GMT+6', offSet: '-06:00' },
  { identifier: 'Etc/GMT+7', offSet: '-07:00' },
  { identifier: 'Etc/GMT+8', offSet: '-08:00' },
  { identifier: 'Etc/GMT+9', offSet: '-09:00' },
  { identifier: 'Etc/GMT-0', offSet: '+00:00' },
  { identifier: 'Etc/GMT-1', offSet: '+01:00' },
  { identifier: 'Etc/GMT-10', offSet: '+10:00' },
  { identifier: 'Etc/GMT-11', offSet: '+11:00' },
  { identifier: 'Etc/GMT-12', offSet: '+12:00' },
  { identifier: 'Etc/GMT-13', offSet: '+13:00' },
  { identifier: 'Etc/GMT-14', offSet: '+14:00' },
  { identifier: 'Etc/GMT-2', offSet: '+02:00' },
  { identifier: 'Etc/GMT-3', offSet: '+03:00' },
  { identifier: 'Etc/GMT-4', offSet: '+04:00' },
  { identifier: 'Etc/GMT-5', offSet: '+05:00' },
  { identifier: 'Etc/GMT-6', offSet: '+06:00' },
  { identifier: 'Etc/GMT-7', offSet: '+07:00' },
  { identifier: 'Etc/GMT-8', offSet: '+08:00' },
  { identifier: 'Etc/GMT-9', offSet: '+09:00' },
  { identifier: 'Etc/GMT0', offSet: '+00:00' },
  { identifier: 'Etc/Greenwich', offSet: '+00:00' },
  { identifier: 'Etc/UCT', offSet: '+00:00' },
  { identifier: 'Etc/UTC', offSet: '+00:00' },
  { identifier: 'Etc/Universal', offSet: '+00:00' },
  { identifier: 'Etc/Zulu', offSet: '+00:00' },
  { identifier: 'Europe/Amsterdam', offSet: '+01:00' },
  { identifier: 'Europe/Andorra', offSet: '+01:00' },
  { identifier: 'Europe/Astrakhan', offSet: '+04:00' },
  { identifier: 'Europe/Athens', offSet: '+02:00' },
  { identifier: 'Europe/Belfast', offSet: '+00:00' },
  { identifier: 'Europe/Belgrade', offSet: '+01:00' },
  { identifier: 'Europe/Berlin', offSet: '+01:00' },
  { identifier: 'Europe/Bratislava', offSet: '+01:00' },
  { identifier: 'Europe/Brussels', offSet: '+01:00' },
  { identifier: 'Europe/Bucharest', offSet: '+02:00' },
  { identifier: 'Europe/Budapest', offSet: '+01:00' },
  { identifier: 'Europe/Busingen', offSet: '+01:00' },
  { identifier: 'Europe/Chisinau', offSet: '+02:00' },
  { identifier: 'Europe/Copenhagen', offSet: '+01:00' },
  { identifier: 'Europe/Dublin', offSet: '+00:00' },
  { identifier: 'Europe/Gibraltar', offSet: '+01:00' },
  { identifier: 'Europe/Guernsey', offSet: '+00:00' },
  { identifier: 'Europe/Helsinki', offSet: '+02:00' },
  { identifier: 'Europe/Isle_of_Man', offSet: '+00:00' },
  { identifier: 'Europe/Istanbul', offSet: '+03:00' },
  { identifier: 'Europe/Jersey', offSet: '+00:00' },
  { identifier: 'Europe/Kaliningrad', offSet: '+02:00' },
  { identifier: 'Europe/Kiev', offSet: '+02:00' },
  { identifier: 'Europe/Kirov', offSet: '+03:00' },
  { identifier: 'Europe/Kyiv', offSet: '+02:00' },
  { identifier: 'Europe/Lisbon', offSet: '+00:00' },
  { identifier: 'Europe/Ljubljana', offSet: '+01:00' },
  { identifier: 'Europe/London', offSet: '+00:00' },
  { identifier: 'Europe/Luxembourg', offSet: '+01:00' },
  { identifier: 'Europe/Madrid', offSet: '+01:00' },
  { identifier: 'Europe/Malta', offSet: '+01:00' },
  { identifier: 'Europe/Mariehamn', offSet: '+02:00' },
  { identifier: 'Europe/Minsk', offSet: '+03:00' },
  { identifier: 'Europe/Monaco', offSet: '+01:00' },
  { identifier: 'Europe/Moscow', offSet: '+03:00' },
  { identifier: 'Europe/Nicosia', offSet: '+02:00' },
  { identifier: 'Europe/Oslo', offSet: '+01:00' },
  { identifier: 'Europe/Paris', offSet: '+01:00' },
  { identifier: 'Europe/Podgorica', offSet: '+01:00' },
  { identifier: 'Europe/Prague', offSet: '+01:00' },
  { identifier: 'Europe/Riga', offSet: '+02:00' },
  { identifier: 'Europe/Rome', offSet: '+01:00' },
  { identifier: 'Europe/Samara', offSet: '+04:00' },
  { identifier: 'Europe/San_Marino', offSet: '+01:00' },
  { identifier: 'Europe/Sarajevo', offSet: '+01:00' },
  { identifier: 'Europe/Saratov', offSet: '+04:00' },
  { identifier: 'Europe/Simferopol', offSet: '+03:00' },
  { identifier: 'Europe/Skopje', offSet: '+01:00' },
  { identifier: 'Europe/Sofia', offSet: '+02:00' },
  { identifier: 'Europe/Stockholm', offSet: '+01:00' },
  { identifier: 'Europe/Tallinn', offSet: '+02:00' },
  { identifier: 'Europe/Tirane', offSet: '+01:00' },
  { identifier: 'Europe/Tiraspol', offSet: '+02:00' },
  { identifier: 'Europe/Ulyanovsk', offSet: '+04:00' },
  { identifier: 'Europe/Uzhgorod', offSet: '+02:00' },
  { identifier: 'Europe/Vaduz', offSet: '+01:00' },
  { identifier: 'Europe/Vatican', offSet: '+01:00' },
  { identifier: 'Europe/Vienna', offSet: '+01:00' },
  { identifier: 'Europe/Vilnius', offSet: '+02:00' },
  { identifier: 'Europe/Volgograd', offSet: '+03:00' },
  { identifier: 'Europe/Warsaw', offSet: '+01:00' },
  { identifier: 'Europe/Zagreb', offSet: '+01:00' },
  { identifier: 'Europe/Zaporozhye', offSet: '+02:00' },
  { identifier: 'Europe/Zurich', offSet: '+01:00' },
  { identifier: 'GB', offSet: '+00:00' },
  { identifier: 'GB-Eire', offSet: '+00:00' },
  { identifier: 'GMT', offSet: '+00:00' },
  { identifier: 'GMT+0', offSet: '+00:00' },
  { identifier: 'GMT-0', offSet: '+00:00' },
  { identifier: 'GMT0', offSet: '+00:00' },
  { identifier: 'Greenwich', offSet: '+00:00' },
  { identifier: 'HST', offSet: '-10:00' },
  { identifier: 'Hongkong', offSet: '+08:00' },
  { identifier: 'Iceland', offSet: '+00:00' },
  { identifier: 'Indian/Antananarivo', offSet: '+03:00' },
  { identifier: 'Indian/Chagos', offSet: '+06:00' },
  { identifier: 'Indian/Christmas', offSet: '+07:00' },
  { identifier: 'Indian/Cocos', offSet: '+06:30' },
  { identifier: 'Indian/Comoro', offSet: '+03:00' },
  { identifier: 'Indian/Kerguelen', offSet: '+05:00' },
  { identifier: 'Indian/Mahe', offSet: '+04:00' },
  { identifier: 'Indian/Maldives', offSet: '+05:00' },
  { identifier: 'Indian/Mauritius', offSet: '+04:00' },
  { identifier: 'Indian/Mayotte', offSet: '+03:00' },
  { identifier: 'Indian/Reunion', offSet: '+04:00' },
  { identifier: 'Iran', offSet: '+03:30' },
  { identifier: 'Israel', offSet: '+02:00' },
  { identifier: 'Jamaica', offSet: '-05:00' },
  { identifier: 'Japan', offSet: '+09:00' },
  { identifier: 'Kwajalein', offSet: '+12:00' },
  { identifier: 'Libya', offSet: '+02:00' },
  { identifier: 'MET', offSet: '+01:00' },
  { identifier: 'MST', offSet: '-07:00' },
  { identifier: 'MST7MDT', offSet: '-07:00' },
  { identifier: 'Mexico/BajaNorte', offSet: '-08:00' },
  { identifier: 'Mexico/BajaSur', offSet: '-07:00' },
  { identifier: 'Mexico/General', offSet: '-06:00' },
  { identifier: 'NZ', offSet: '+13:00' },
  { identifier: 'NZ-CHAT', offSet: '+13:45' },
  { identifier: 'Navajo', offSet: '-07:00' },
  { identifier: 'PRC', offSet: '+08:00' },
  { identifier: 'PST8PDT', offSet: '-08:00' },
  { identifier: 'Pacific/Apia', offSet: '+13:00' },
  { identifier: 'Pacific/Auckland', offSet: '+13:00' },
  { identifier: 'Pacific/Bougainville', offSet: '+11:00' },
  { identifier: 'Pacific/Chatham', offSet: '+13:45' },
  { identifier: 'Pacific/Chuuk', offSet: '+10:00' },
  { identifier: 'Pacific/Easter', offSet: '-05:00' },
  { identifier: 'Pacific/Efate', offSet: '+11:00' },
  { identifier: 'Pacific/Enderbury', offSet: '+13:00' },
  { identifier: 'Pacific/Fakaofo', offSet: '+13:00' },
  { identifier: 'Pacific/Fiji', offSet: '+12:00' },
  { identifier: 'Pacific/Funafuti', offSet: '+12:00' },
  { identifier: 'Pacific/Galapagos', offSet: '-06:00' },
  { identifier: 'Pacific/Gambier', offSet: '-09:00' },
  { identifier: 'Pacific/Guadalcanal', offSet: '+11:00' },
  { identifier: 'Pacific/Guam', offSet: '+10:00' },
  { identifier: 'Pacific/Honolulu', offSet: '-10:00' },
  { identifier: 'Pacific/Johnston', offSet: '-10:00' },
  { identifier: 'Pacific/Kanton', offSet: '+13:00' },
  { identifier: 'Pacific/Kiritimati', offSet: '+14:00' },
  { identifier: 'Pacific/Kosrae', offSet: '+11:00' },
  { identifier: 'Pacific/Kwajalein', offSet: '+12:00' },
  { identifier: 'Pacific/Majuro', offSet: '+12:00' },
  { identifier: 'Pacific/Midway', offSet: '-11:00' },
  { identifier: 'Pacific/Nauru', offSet: '+12:00' },
  { identifier: 'Pacific/Niue', offSet: '-11:00' },
  { identifier: 'Pacific/Norfolk', offSet: '+12:00' },
  { identifier: 'Pacific/Noumea', offSet: '+11:00' },
  { identifier: 'Pacific/Pago_Pago', offSet: '-11:00' },
  { identifier: 'Pacific/Palau', offSet: '+09:00' },
  { identifier: 'Pacific/Pitcairn', offSet: '-08:00' },
  { identifier: 'Pacific/Pohnpei', offSet: '+11:00' },
  { identifier: 'Pacific/Ponape', offSet: '+11:00' },
  { identifier: 'Pacific/Port_Moresby', offSet: '+10:00' },
  { identifier: 'Pacific/Rarotonga', offSet: '-10:00' },
  { identifier: 'Pacific/Saipan', offSet: '+10:00' },
  { identifier: 'Pacific/Samoa', offSet: '-11:00' },
  { identifier: 'Pacific/Tahiti', offSet: '-10:00' },
  { identifier: 'Pacific/Tarawa', offSet: '+12:00' },
  { identifier: 'Pacific/Tongatapu', offSet: '+13:00' },
  { identifier: 'Pacific/Truk', offSet: '+10:00' },
  { identifier: 'Pacific/Wake', offSet: '+12:00' },
  { identifier: 'Pacific/Wallis', offSet: '+12:00' },
  { identifier: 'Pacific/Yap', offSet: '+10:00' },
  { identifier: 'Poland', offSet: '+01:00' },
  { identifier: 'Portugal', offSet: '+00:00' },
  { identifier: 'ROC', offSet: '+08:00' },
  { identifier: 'ROK', offSet: '+09:00' },
  { identifier: 'Singapore', offSet: '+08:00' },
  { identifier: 'Turkey', offSet: '+03:00' },
  { identifier: 'UCT', offSet: '+00:00' },
  { identifier: 'US/Alaska', offSet: '-09:00' },
  { identifier: 'US/Aleutian', offSet: '-10:00' },
  { identifier: 'US/Arizona', offSet: '-07:00' },
  { identifier: 'US/Central', offSet: '-06:00' },
  { identifier: 'US/East-Indiana', offSet: '-05:00' },
  { identifier: 'US/Eastern', offSet: '-05:00' },
  { identifier: 'US/Hawaii', offSet: '-10:00' },
  { identifier: 'US/Indiana-Starke', offSet: '-06:00' },
  { identifier: 'US/Michigan', offSet: '-05:00' },
  { identifier: 'US/Mountain', offSet: '-07:00' },
  { identifier: 'US/Pacific', offSet: '-08:00' },
  { identifier: 'US/Samoa', offSet: '-11:00' },
  { identifier: 'UTC', offSet: '+00:00' },
  { identifier: 'Universal', offSet: '+00:00' },
  { identifier: 'W-SU', offSet: '+03:00' },
  { identifier: 'WET', offSet: '+00:00' },
  { identifier: 'Zulu', offSet: '+00:00' }
]
export default timezoneData
