import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, Box, Button, Typography } from '@mui/material'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import SettingsIcon from '@mui/icons-material/Settings'
import config from '../../config'
// import { Tabnames } from '../../enums'

function WorkspaceAndFlow({ orgId, orgObj, usersList }) {
  const navigate = useNavigate()

  const handleMembers = () => {
    navigate(`${config.orgBaseUrl}/${orgId}/invite`)
  }
  const handleSettings = () => {
    navigate(`${config.orgBaseUrl}/${orgId}/setting`)
  }

  //   const handleNavigateToProject = (value) => {
  //     navigate(`${config.projectsBaseUrl}/${orgId}/${value.project_id}${config.workflowBaseUrl}/${value.id}/${Tabnames.DRAFT}`)
  //   }

  //   const timeAgo = (date) => {
  //     const diff = Date.now() - new Date(date).getTime() // .getTime() ensures we're comparing milliseconds
  //     const d = Math.floor(diff / 86400000),
  //       h = Math.floor(diff / 3600000) % 24,
  //       m = Math.floor(diff / 60000) % 60

  //     if (d > 0) {
  //       return `${d} day${d > 1 ? 's' : ''} ago`
  //     }

  //     if (h > 0) {
  //       return `${h} hour${h > 1 ? 's' : ''} ago`
  //     }

  //     if (m > 0) {
  //       return `${m} minute${m > 1 ? 's' : ''} ago`
  //     }

  //     const seconds = Math.floor(diff / 1000)
  //     return `${seconds} second${seconds > 1 ? 's' : ''} ago`
  //   }

  return (
    <Box minHeight='25vmax' className='flex relative w-100'>
      {/* Workspace */}
      <Box className='flex column' width='30%' border='1px solid black' bor borderLeft='none' height='100%'>
        <Box className='flex column' height='80%' justifyContent='center' alignItems='center'>
          <Box
            className='flex'
            width='5vmax'
            height='5vmax'
            borderRadius='50%'
            justifyContent='center'
            alignItems='center'
            bgcolor='#DEDEDE'
          >
            <Avatar padding='0.5vmax' sx={{ bgcolor: 'pink', border: '10px solid white' }} alt={orgObj?.name} src={orgObj?.meta?.iconUrl} />
          </Box>
          <Box marginTop='1vmax' className='w-100 flex column' justifyContent='center' alignItems='center'>
            <Typography variant='h6'>{orgObj?.name}</Typography>
            <Typography variant='caption'>{orgObj?.meta?.domainUrl}</Typography>
            <Typography variant='caption' margin='-3px'>
              {orgObj.timezone}
            </Typography>
          </Box>
        </Box>
        <Box className='flex w-100' borderTop='1px solid #e0e0e0' height='20%'>
          <Button
            className='w-100 flex gap-2 '
            onClick={handleMembers}
            justifyContent='center'
            alignItems='center'
            sx={{ borderRight: '1px solid #e0e0e0' }}
          >
            <PeopleAltIcon />
            Members{` (${usersList ? usersList?.length : 0})`}
          </Button>
          <Button className='w-100 flex gap-2 ' onClick={handleSettings} justifyContent='center' alignItems='center'>
            <SettingsIcon />
            Settings
          </Button>
        </Box>
      </Box>
      {/* Flow */}
      <Box
        position='relative'
        className='h-100 p-5 flex column gap-3'
        width='70%'
        overflow='auto'
        border='1px solid black'
        borderLeft='none'
        borderRight='none'
      />
    </Box>
  )
}

export default WorkspaceAndFlow
