import React, { useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
// import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined'

import { getAfterSignupData } from '../../api'
import { getTableId } from '../../config'
// import CheckCircleIcon from '@mui/icons-material/CheckCircle'
function GettingStarted({ handleOpen }) {
  // const data = {
  //     labels: ['Completed', 'Remaining'],
  //     datasets: [
  //       {
  //         data: [1, 3], // The values for "Completed" and "Remaining"
  //         backgroundColor: ['#a32c2c', '#eaeaea'], // Completed color, Remaining color
  //         borderWidth: 0,
  //         borderRadius: 10 // Round the edges of each segment
  //       }
  //     ]
  //   }

  //   const options = {
  //     responsive: true,
  //     cutout: '80%', // Adjust the size of the inner circle
  //     plugins: {
  //       legend: {
  //         display: false // Hide legend
  //       },
  //       tooltip: {
  //         enabled: false // Hide tooltips
  //       }
  //     }
  //   }
  const [tableData, setTableData] = useState([])
  const tableId = getTableId('videoLibrary')
  useEffect(() => {
    getAfterSignupData(tableId).then((data) => setTableData(data))
  }, [])
  return (
    <Box className='w-100 h-40 flex b-1'>
      <Box className='w-100'>
        <Box className='flex gap-3 px-5 py-5'>
          {/* <Box position='relative' display='inline-flex'>
            <Doughnut width={100} data={data} options={options} />
            <Box
              position='absolute'
              top='50%'
              left='50%'
              sx={{
                transform: 'translate(-50%, -50%)',
                fontSize: '1.3rem',
                fontWeight: '500'
              }}
            >
              1/4
            </Box>
          </Box> */}
          <Box>
            <Typography variant='h3' fontFamily='Times New Roman'>
              Getting Started
            </Typography>
            <Typography color='#6B6B6B' fontWeight={600} variant='subtitle2'>
              Take your first step to build a successful workflow{' '}
            </Typography>
          </Box>
        </Box>
        <Box className='w-100 flex flex-wrap'>
          {tableData.map((item) => (
            <Button key={item.rowid} className='flex w-50' sx={{ border: '1px solid #e0e0e0' }} onClick={() => handleOpen(item)}>
              <Box justifyContent='space-between' alignContent='center' className='w-100  p-4 flex'>
                <Typography variant='subtitle1' display='flex' alignItems='center'>
                  {' '}
                  <OndemandVideoOutlinedIcon className='mr-2' /> {item.name}
                </Typography>{' '}
                <Box justifyContent='center' alignItems='center' className='flex gap-2'>
                  {item?.est_time}
                  {/* <PlayCircleFilledRoundedIcon  fontSize='medium' />{' '} */}
                  {/* <CheckCircleIcon color='success' fontSize='small' />{' '} */}
                </Box>
              </Box>
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default GettingStarted
