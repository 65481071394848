import { takeEvery, takeLatest } from 'redux-saga/effects'
import {
  createNewPlugInSaga,
  getAllPluginSaga,
  getAuthAndActionsSaga,
  getPluginDetailsSaga,
  updatetPluginDetailsSaga,
  generatePluginDetailsAndActionsFromAI
} from './dhPluginFunctions.ts'

export default function* DeveloperHubPluginSaga() {
  yield takeLatest('DHPlugin/getAllPluginStart', getAllPluginSaga)
  yield takeLatest('DHPlugin/createNewPluginStart', createNewPlugInSaga)
  yield takeLatest('DHPlugin/createNewPluginSuccess', generatePluginDetailsAndActionsFromAI)
  yield takeEvery('DHPlugin/getPluginDetailStart', getPluginDetailsSaga)
  yield takeEvery('DHPlugin/getPluginDetailSuccess', getAuthAndActionsSaga)
  yield takeLatest('DHPlugin/updatePluginDetailsStart', updatetPluginDetailsSaga)
}
