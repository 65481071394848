import { createAsyncThunk } from '@reduxjs/toolkit'
import { batch } from 'react-redux'
import {
  createScripts,
  getAllScrpits,
  updateScripts,
  updateScriptStatus,
  moveFlow,
  duplicateFlow,
  createFlowFromTemplate,
  getNameDescriptionByAi,
  dryRunScripts
} from '../../api/index' // add route to update status of script
// import { errorToast } from '../../components/customToast'
import { ApiTypes, MiscTypes, Tabnames } from '../../enums'
import { setFlowAndDraftFlowJson, setMetaDataForFlowJson, setPublishedFlowJson } from '../flowJson/flowJsonSliceV2.ts'
import { generateNewId, getStringToArrayObject, sendDataToParentInEmbed } from '../../utils/utilities'
import { setIsDryRunResizerOpen } from '../appInfo/appInfoSlice.ts'
import { setDryRunResponseForScript } from './scriptsSlice'

export const saveScripts = createAsyncThunk('scripts/saveScripts', async (payload) => {
  const identifier = generateNewId(8)
  const { data } = await createScripts({ ...payload, identifier: `scri${identifier}` }, payload.type || ApiTypes.FLOW)
  return data.data
})

export const saveScriptTemplate = createAsyncThunk('scripts/saveScriptTemplate', async (payload) => {
  const data = await createFlowFromTemplate(payload.projectId, payload.templateId, { projectId: payload.projectId })
  return data.data.data
})

export const fetchScripts = createAsyncThunk('scripts/fetchScripts', async (payload) => {
  const data = await getAllScrpits(payload.id, payload?.type || ApiTypes.FLOW)
  return data.data.data
})

export const fetchNameDescriptionByAi = createAsyncThunk('scripts/fetchNameDescriptionByAi', async (payload) => {
  const { scriptId, projectId } = payload
  const data = await getNameDescriptionByAi(scriptId, projectId)
  return data
})

export const removeScripts = createAsyncThunk('scripts/removeScripts', async () => {
  return 5
})

export const changeScriptStatus = createAsyncThunk('scripts/changeScriptStatus', async (payload, { getState, dispatch }) => {
  const { scriptId, projectId, status } = payload
  const publishUsedVariables = getState()?.flowJsonV2?.[scriptId]?.publishUsedVariables
  const version = getState().flowJsonV2[scriptId]?.flowJson?.version || ''
  const data = await updateScriptStatus(projectId, scriptId, status)
  const scriptDetails = data?.data?.data
  dispatch(setFlowAndDraftFlowJson(scriptDetails?.json_script))
  let action
  switch (status) {
    case '0':
      action = 'deleted'
      break
    case '1':
      action = parseInt(version?.split('.')[0], 10) >= 1 ? 'published' : 'initiated'
      break
    case '2':
      action = parseInt(version?.split('.')[0], 10) >= 1 ? 'paused' : 'initiated'
      break
    default:
      break
  }
  sendDataToParentInEmbed(
    action,
    scriptId,
    scriptDetails?.metadata?.description,
    scriptDetails?.title,
    publishUsedVariables?.payloadData?.context?.req || {}
  )
  return {
    Data: scriptDetails,
    NewStatus: payload?.status,
    OldStatus: payload?.oldstatus || payload?.scriptInfo?.status
  }
  // return data
})

export const moveScript = createAsyncThunk('scripts/moveFlow', async (payload) => {
  const data = await moveFlow(payload?.newProjectId, payload?.duplicateFlowId)
  // if (!data?.success) {
  //   errorToast('Error while moving Flow')
  // }
  return {
    data: data.data,
    currentProjectid: payload?.currentProjectid,
    duplicateFlowId: payload?.duplicateFlowId,
    newProjectId: payload?.newProjectId
  }
})

export const duplicateScript = createAsyncThunk('scripts/duplicateFlow', async (payload) => {
  const data = await duplicateFlow(payload?.newProjectId, payload?.duplicateFlowId, payload?.newflowtitle)
  // if (!data?.success) {
  //   errorToast('Error while duplicating the flow')
  // }
  return {
    data: data?.data,
    currentProjectid: payload?.currentProjectid,
    duplicateFlowId: payload?.duplicateFlowId,
    newProjectId: payload?.newProjectId
  }
})

export const updateScript = createAsyncThunk('scripts/updateScript', async (payload, { dispatch, getState }) => {
  try {
    const { scriptId } = payload
    const scriptDetails = getState().scripts.scripts?.[scriptId]

    const dataToSend = {
      ...payload?.dataToSend,
      script: scriptDetails?.script,
      project_id: scriptDetails?.project_id,
      type: MiscTypes.FLOW
    }
    const response = await updateScripts(scriptId, dataToSend)
    batch(() => {
      dispatch(setFlowAndDraftFlowJson(response?.data?.data?.json_script))
      // dispatch(setFlowAndDraftFlowJson(JSON.parse(response?.data?.data?.json_script)))
      dispatch(setPublishedFlowJson(response?.data?.data?.published_json_script))
      dispatch(setMetaDataForFlowJson({ ...response?.data?.data }))

      // dispatch(setPublishedFlowJson(JSON.parse(response?.data?.data?.published_json_script)))
    })
    return response?.data?.data
  } catch (error) {
    console.error(error, 33)
    throw error
  }
})

export const dryRunScriptThunk = createAsyncThunk(
  'scripts/dryRunScript',
  async ({ sectionIdOrScriptId, tabName, postData, dryRunInstance }, { dispatch }) => {
    dispatch(setIsDryRunResizerOpen(true))
    let type = MiscTypes.CODE
    if (window.location.pathname.includes('workflow')) {
      type = MiscTypes.FLOW
    }
    const headersToSend = dryRunInstance?.headers?.length !== 0 ? getStringToArrayObject(2, dryRunInstance?.headers) : []
    const code = {
      url: dryRunInstance?.url?.split('?')[0],
      headers: headersToSend,
      queryString: getStringToArrayObject(1, dryRunInstance?.queryParams),
      method: dryRunInstance?.httpRequestType?.toUpperCase(),
      postData
    }

    const datasend = {
      data: code,
      type,
      isPublishedTab: tabName === Tabnames.PUBLISH
    }
    try {
      const data = await dryRunScripts(sectionIdOrScriptId, datasend)
      dispatch(setDryRunResponseForScript(data?.data?.data?.response))
    } catch (error) {
      // errorToast(error?.response?.data?.message)
    }
  }
)
