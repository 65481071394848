import { useEffect, useState } from 'react'

function ChatWidget() {
  const helloConfig = {
    widgetToken: 'a13cc',
    show_close_button: false,
    hide_launcher: true
  }
  const [init, setinit] = useState(false)
  useEffect(() => {
    // Prevent script from being added multiple times
    if (document.querySelector('script[src="https://control.msg91.com/app/assets/widget/chat-widget.js"]')) {
      return () => {
        window.chatWidget.close()
      } // Exit if the script is already loaded
    }
    // Dynamically create the script element to load the chat widget
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://control.msg91.com/app/assets/widget/chat-widget.js'
    script.async = true
    script.onload = () => {
      // Initialize the widget after the script loads
      if (!init) {
        window.initChatWidget(helloConfig, 5000) // delay of 5000ms
        setinit(true)
      }
    }
    document.body.appendChild(script)

    // Cleanup function to remove the script when the component is unmounted
    return () => {
      document.body.removeChild(script)
    }
  }, []) // Only run once when helloConfig changes
  return null
}

export default ChatWidget
