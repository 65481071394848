import React, { useState } from 'react'
import axios from 'axios'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import CloseIcon from '@mui/icons-material/Close'
import { useSelector, useDispatch } from 'react-redux'
import { Box, TextField, Button, Typography, DialogContent, DialogTitle, IconButton, Dialog } from '@mui/material'
import SocialShare from '../../../share/socialShare.tsx'
import { ALERTTYPES, ParamsEnums } from '../../../../enums'
import addUrlDataHoc from '../../../../hoc/addUrlDataHoc.tsx'
import { $ReduxCoreType } from '../../../../types/reduxCore.ts'
import { sendAlert } from '../../../../utils/alertUtility.ts'
import { successToast } from '../../../customToast'
import { toggleisFeedbackSubmitted } from '../../../../store/appInfo/appInfoSlice.ts'

interface FeedBackModalProps {
  orgId: string
  setReview: (review: boolean) => void
}

function FeedBackModalV2({ orgId, setReview }: FeedBackModalProps): React.ReactElement {
  const { orgObj, userName, userId, userEmail } = useSelector((state: $ReduxCoreType) => ({
    userId: state?.user?.userId,
    userEmail: state?.user?.userEmail,
    orgObj: state?.orgs?.orgs?.[orgId],
    userName: state?.user?.userName
  }))
  const dispatch = useDispatch()
  const [feedback, setFeedback] = useState({ feedback: '', rating: '' })
  const [error, setError] = useState(false)
  const [modal, setModal] = useState(false)

  const handleCloseModal = () => {
    setFeedback({ feedback: '', rating: '' })
    if (feedback?.rating === 'good') {
      handleSubmit()
    }
    setModal(false)
  }
  const handleOpenModal = (rating: string) => {
    feedback.rating = rating
    setModal(true)
  }
  const handleSubmit = async () => {
    const data = {
      orgName: orgObj?.name,
      name: userName,
      email: userEmail,
      userId,
      feedback: feedback?.feedback,
      rating: feedback?.rating, // Assuming the rating is part of the feedback (could be 'good' or 'bad')
      orgId,
      environment: process.env.REACT_APP_API_ENVIRONMENT
    }
    setError(false)
    if (feedback?.rating === 'bad' && !feedback?.feedback) {
      setError(true)
      return
    }
    try {
      await axios.post('https://flow.sokt.io/func/scriQuTCWUtz', data)
      successToast('Thankyou For Your Feedback!')
      setFeedback({ feedback: '', rating: '' })
      setReview(true)
      dispatch(toggleisFeedbackSubmitted(true))
    } catch (error) {
      const err = error as Error
      sendAlert({ message: err.message, type: ALERTTYPES.FRONTEND, description: 'feedback modal submit error' })
    }
  }
  return (
    <>
      <Box className='gap-2 flex items-center'>
        <IconButton onClick={() => handleOpenModal('good')} aria-label='Satisfied' size='medium'>
          <SentimentSatisfiedAltIcon color='success' fill fontSize='inherit' />
        </IconButton>
        <IconButton onClick={() => handleOpenModal('bad')} aria-label='Dissatisfied' size='medium'>
          <SentimentVeryDissatisfiedIcon color='error' fontSize='inherit' />
        </IconButton>
      </Box>

      <Dialog
        PaperProps={{
          style: {
            border: '2px solid #000'
          }
        }}
        open={modal}
        onClose={handleCloseModal}
      >
        <DialogTitle variant='h5' fontWeight='500' className='p-3 border-bottom ' position='relative'>
          {' '}
          {feedback?.rating === 'good' ? 'Your Support Means the World' : 'Tell us what went wrong'}
          <IconButton
            edge='end'
            className='p-3'
            color='inherit'
            onClick={handleCloseModal}
            aria-label='close'
            style={{ position: 'absolute', right: 0, top: '50%', transform: 'translate(-30%, -50%)' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={feedback?.rating === 'good' ? 'p-0' : ''}>
          <Box className='column w-100'>
            <Box className='column'>
              {feedback?.rating === 'good' ? (
                <>
                  <Typography className='p-3' variant='subtitle1'>
                    We humbly ask for your support in sharing your Viasocket journey with others. Your personal story has the power to
                    assist those seeking guidance. Every share makes a difference, and we are profoundly grateful for any help you can offer
                    in spreading the word about Viasocket!
                  </Typography>
                  <Box className='flex pt-2'>
                    <SocialShare />
                  </Box>
                </>
              ) : (
                <>
                  <Typography className='py-3' variant='subtitle1'>
                    Your feedback matters! Help us understand what didn&apos;t work for you.
                  </Typography>
                  <TextField
                    placeholder='Enter Feedback here'
                    id='feedback'
                    label='Write Your Feedback here'
                    multiline
                    rows={4}
                    value={feedback?.feedback}
                    onChange={(e) => setFeedback((prevState) => ({ ...prevState, feedback: e.target.value }))}
                    variant='outlined'
                  />

                  {error && <Typography className='color-red'>Feedback is required</Typography>}

                  <Box className='flex-verticalcenter gap-3 pt-3'>
                    <Button onClick={handleSubmit} variant='contained'>
                      Submit Feedback
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default React.memo(addUrlDataHoc(React.memo(FeedBackModalV2), [ParamsEnums.slugName, ParamsEnums.orgId]))
