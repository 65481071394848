import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Alarm, Email, Webhook } from '@mui/icons-material'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { Box, Button, Card, TextField, Typography } from '@mui/material'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums, Tabnames, THUNK_CALL_STATUS } from '../../enums'
import { updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import { saveScripts } from '../../store/scripts/scriptsThunk'
import config from '../../config'
import IconWrapper from '../IconWrapper/IconWrapper.tsx'

interface CustomOptionsToCreateFlowProps {
  orgId: string
  finalProjectId: string
}

/**
 * Component for creating custom options to create a flow.
 *
 * @param {CustomOptionsToCreateFlowProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
function CustomOptionsToCreateFlow({ orgId, finalProjectId }: CustomOptionsToCreateFlowProps): JSX.Element {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState('')
  /**
   * Navigate to a dedicated page and create a flow.
   *
   * @param {string} event - The event type.
   */
  const navigateToDedicatePage = (event: string): void => {
    dispatch(updateAppInfoState({ openModal: true, modalType: event }))
    createFlow(event)
  }

  /**
   * Create a flow with optional event ID.
   *
   * @param {string | false} [eventId=false] - The event ID.
   */
  const createFlow = (eventId: string | false = false): void => {
    let triggerDetails
    if (eventId) triggerDetails = { triggerType: eventId, type: 'add' }
    dispatch(updateAppInfoState({ modalType: eventId }))
    dispatch(
      saveScripts({
        project_id: finalProjectId,
        org_id: orgId,
        triggerDetails
      })
    ).then((e) => {
      if (e?.meta?.requestStatus === THUNK_CALL_STATUS?.FULFILLED) {
        navigate(
          `${config.projectsBaseUrl}/${orgId}/${finalProjectId}${config.workflowBaseUrl}/${e.payload.id}/${Tabnames.DRAFT}${
            eventId ? '' : '?stepId=SET_TRIGGER&slugName=selecttrigger'
          }`
        )
      }
    })
  }

  return (
    <Box className='column pt-2 w-100'>
      <Box className='flex-spaceBetween-center'>
        <Typography variant='h4' className=' pl-1'>
          Create new flow
        </Typography>
        <Button variant='outlined' onClick={() => createFlow()}>
          Create Blank Flow
        </Button>
      </Box>
      <Box className='flex-start-center py-3 gap-2 flex-center w-100'>
        <Card
          variant='outlined'
          className=' service-block service-block__no-description flex-start-center w-100 gap-2'
          onClick={() => navigateToDedicatePage('webhook')}
        >
          <IconWrapper component={<Webhook />} size='28px' />
          When trigger by API call or webhook
        </Card>
        <Card
          variant='outlined'
          className=' service-block w-100 service-block__no-description flex-start-center gap-2'
          onClick={() => navigateToDedicatePage('cron')}
        >
          <IconWrapper component={<Alarm />} size='28px' />
          Which repeats at regular intervals
        </Card>
        <Card
          variant='outlined'
          className=' service-block w-100 service-block__no-description flex-start-center gap-2'
          onClick={() => navigateToDedicatePage('email')}
        >
          <IconWrapper component={<Email />} size='28px' />
          When you receive certain email
        </Card>
      </Box>
      <Box className='pt-3'>
        <Box className='flex-center mb-3'>
          <Typography variant='h6'>&nbsp;Let&apos;s automate using AI</Typography>
          <AutoAwesomeIcon style={{ fontSize: '2rem' }} />
        </Box>
        <Box className='w-100 aifield'>
          <TextField
            style={{ background: 'white' }}
            placeholder='Example: When receive message on Slack, create a card on Trello'
            variant='outlined'
            fullWidth
            rows={1}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </Box>
        <Box className='flex-center gap-2 my-4'>
          <Button
            variant='contained'
            onClick={() => {
              dispatch(updateAppInfoState({ flowByAiWhenCreateFlowText: inputValue.trim() }))
              createFlow()
            }}
          >
            Create new flow by AI &nbsp;
            <AutoAwesomeIcon style={{ fontSize: '1rem' }} />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
export default React.memo(addUrlDataHoc(React.memo(CustomOptionsToCreateFlow), [ParamsEnums.orgId]))
