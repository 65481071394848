import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import AddIcon from '@mui/icons-material/Add'
import ApiSharpIcon from '@mui/icons-material/ApiSharp'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import CodeIcon from '@mui/icons-material/Code'
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp'
import DataObjectIcon from '@mui/icons-material/DataObject'
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import EditSharpIcon from '@mui/icons-material/EditSharp'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import InsertCommentIcon from '@mui/icons-material/InsertComment'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import RefreshIcon from '@mui/icons-material/Refresh'
import SearchIcon from '@mui/icons-material/Search'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography
} from '@mui/material'
import React from 'react'
import SearchBar from '../../components/projectdashboard/searchBar'
// Sample color and typography tokens

function HeadingComponent({ title }) {
  return (
    <Typography variant='h4' align='center' sx={{ mb: 2 }}>
      {title}
    </Typography>
  )
}

const colors = {
  primary: '#333333',
  background: '#f0f0f0',
  rightSlider: '#f5f5f5',
  textPrimary: '#333',
  textSecondary: '#555'
}

const typography = {
  h1: 'Times New Roman, sans-serif',
  h2: 'Times New Roman, sans-serif',
  body: 'Times New Roman, sans-serif'
}
const typographyVariants = [
  { title: 'Heading 1', variant: 'h1' },
  { title: 'Heading 2', variant: 'h2' },
  { title: 'Heading 3', variant: 'h3' },
  { title: 'Heading 4', variant: 'h4' },
  { title: 'Heading 5', variant: 'h5' },
  { title: 'Heading 6', variant: 'h6' },
  { title: 'Base', variant: 'base' },
  { title: 'Base 1', variant: 'base1' },
  { title: 'Base 2', variant: 'base2' },
  { title: 'Base 3', variant: 'base3' }
]
function DesignSystemPage() {
  const [activeTab, setActiveTab] = React.useState(0)

  return (
    <div>
      {/* Main Content */}
      <Container maxWidth='lg' sx={{ mt: 4 }}>
        <Box
          sx={{
            textAlign: 'center',
            py: 5,
            mb: 14
          }}
        >
          <Typography
            variant='h2'
            sx={{
              color: colors.textPrimary,
              fontWeight: 'bold',
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)'
              }
            }}
          >
            Design System of Viasocket
          </Typography>
          <Typography
            variant='h6'
            sx={{
              fontFamily: 'Arial, sans-serif',
              color: colors.textSecondary,
              fontWeight: 'bold',
              mt: 2,
              transition: 'opacity 0.3s ease-in-out',
              opacity: 0.8,
              '&:hover': {
                opacity: 1
              }
            }}
          >
            A comprehensive guide to our design tokens and components
          </Typography>
        </Box>

        {/* Color Palette */}
        <Box sx={{ mb: 4 }}>
          <HeadingComponent title='Color Palette' />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Card sx={{ backgroundColor: colors.primary, color: '#fff' }}>
                <CardContent>
                  <Typography variant='h6'>Primary Color</Typography>
                  <Typography variant='body1'>{colors.primary}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ backgroundColor: colors.background }}>
                <CardContent>
                  <Typography variant='h6'>Background Color</Typography>
                  <Typography variant='body1'>{colors.background}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ backgroundColor: colors.rightSlider }}>
                <CardContent>
                  <Typography variant='h6'>Sliders Color</Typography>
                  <Typography variant='body1'>{colors.rightSlider}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ mb: 2 }} />
        {/* Typography Tokens */}
        <Box sx={{ mb: 4 }}>
          <HeadingComponent title='Typography Tokens' />
          <Grid container spacing={2} direction='column'>
            {typographyVariants.map((variant) => (
              <Grid item key={variant}>
                <Typography variant={variant?.variant} sx={{ fontFamily: typography[variant] }}>
                  {variant?.title}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Divider sx={{ mb: 2 }} />
        {/* Buttons */}
        <Box sx={{ mb: 4 }}>
          <HeadingComponent title='Buttons' />
          <Grid container spacing={2}>
            <Grid item>
              <Button variant='contained'>Primary Button</Button>
            </Grid>
            <Grid item>
              <Button variant='outlined'>Secondary Button</Button>
            </Grid>
            <Grid item>
              <Button variant='text'>Text Button</Button>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ mb: 8 }} />

        {/* Icons */}
        <Box sx={{ mb: 4 }}>
          <HeadingComponent title='Icons' />
          <Box component='table' sx={{ width: '100%', borderCollapse: 'collapse' }}>
            <Box component='tr'>
              <Box component='td' sx={{ padding: '8px', textAlign: 'center' }}>
                <FullscreenIcon />
              </Box>
              <Box component='td' sx={{ padding: '8px', textAlign: 'center' }}>
                <ApiSharpIcon />
              </Box>
              <Box component='td' sx={{ padding: '8px', textAlign: 'center' }}>
                <ExpandMoreIcon />
              </Box>
              <Box component='td' sx={{ padding: '8px', textAlign: 'center' }}>
                <AddIcon />
              </Box>
              <Box component='td' sx={{ padding: '8px', textAlign: 'center' }}>
                <FullscreenExitIcon />
              </Box>
              <Box component='td' sx={{ padding: '8px', textAlign: 'center' }}>
                <DataObjectIcon />
              </Box>
              <Box component='td' sx={{ padding: '8px', textAlign: 'center' }}>
                <EditSharpIcon />
              </Box>
              <Box component='td' sx={{ padding: '8px', textAlign: 'center' }}>
                <CodeIcon />
              </Box>
              <Box component='td' sx={{ padding: '8px', textAlign: 'center' }}>
                <CloseIcon />
              </Box>
              <Box component='td' sx={{ padding: '8px', textAlign: 'center' }}>
                <AccessAlarmIcon />
              </Box>
              <Box component='td' sx={{ padding: '8px', textAlign: 'center' }}>
                <DeleteSharpIcon />
              </Box>
              <Box component='td' sx={{ padding: '8px', textAlign: 'center' }}>
                <InsertCommentIcon />
              </Box>
              <Box component='td' sx={{ padding: '8px', textAlign: 'center' }}>
                <ContentCopySharpIcon />
              </Box>
              <Box component='td' sx={{ padding: '8px', textAlign: 'center' }}>
                <CheckCircleOutlineIcon color='success' />
              </Box>
              <Box component='td' sx={{ padding: '8px', textAlign: 'center' }}>
                <RefreshIcon />
              </Box>
              <Box component='td' sx={{ padding: '8px', textAlign: 'center' }}>
                <DoDisturbIcon color='error' />
              </Box>
              <Box component='td' sx={{ padding: '8px', textAlign: 'center' }}>
                <SearchIcon />
              </Box>
            </Box>
          </Box>
        </Box>

        <Divider sx={{ mb: 4 }} />

        <Box component='div' sx={{ padding: '8px', textAlign: 'center', mb: 4 }}>
          <HeadingComponent title='Tabs' />
          <Tabs className='mr-1' value={activeTab} onChange={(e, newVal) => setActiveTab(newVal)}>
            <Tab value={0} selected label='hello' />
            <Tab value={1} label='Okk' />
            <Tab value={2} label='bye' />
          </Tabs>
        </Box>

        <Divider sx={{ mb: 8 }} />
        <Box component='div' sx={{ padding: '8px', textAlign: 'center', mb: 4 }}>
          <HeadingComponent title='Accordion' />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Accordion className='mr-1'>
                <AccordionSummary expandIcon={<KeyboardArrowRightIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                  <Typography>Heading</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi, rerum veniam fugiat deserunt sunt ipsa provident ipsum
                    ad culpa qui itaque aspernatur expedita error aliquam consequuntur, maiores labore facere. Pariatur cum asperiores nulla
                    libero aspernatur. Iste aperiam libero officia dolorum, quas similique odio quod nulla atque ad necessitatibus suscipit
                    error.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ mb: 8 }} />
        <Box component='div' sx={{ padding: '8px', textAlign: 'center', mb: 4 }}>
          <HeadingComponent title='Input' />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Search Bar</Typography>
              <SearchBar />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1'>Standard Input</Typography>
              <Box component='div' sx={{ padding: '8px', textAlign: 'center' }}>
                <input
                  type='text'
                  placeholder='Standard'
                  style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1'>Outlined Input</Typography>
              <Box component='div' sx={{ padding: '8px', textAlign: 'center' }}>
                <input
                  type='text'
                  placeholder='Outlined'
                  style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', outline: '2px solid #333' }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1'>Filled Input</Typography>
              <Box component='div' sx={{ padding: '8px', textAlign: 'center' }}>
                <input
                  type='text'
                  placeholder='Filled'
                  style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', backgroundColor: '#f4f4f4' }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  )
}
export default DesignSystemPage
