import React, { useState } from 'react'
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom'
import { Box, Stack } from '@mui/system'
import { Avatar, Collapse, Divider, IconButton, List, ListItem, ListItemButton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import config from '../../config'
import IconWrapper from '../IconWrapper/IconWrapper.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { handleLogOut } from './WorkspacesComponent.tsx'

interface SelectedWorkspaceProps {
  orgId: string
  open: boolean
  setOpen: (open: boolean) => void
  usersList: Array<any>
}
function SelectedWorkspace({ orgId, open, setOpen, usersList }: SelectedWorkspaceProps) {
  const { orgObj } = useCustomSelector((state) => ({
    orgObj: state?.orgs?.orgs?.[orgId]
  }))
  const orgIcon = orgObj?.meta?.iconUrl

  const navigate = useNavigate()
  const handleBackButton = () => navigate('/org')
  const [showOnHover, setShowOnHover] = useState(false)

  const handleNavigations = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, to: string) => {
    e.stopPropagation()
    switch (to) {
      case 'orgs':
        navigate(config.orgBaseUrl)
        break

      case 'setting':
        navigate(`${config.orgBaseUrl}/${orgId}/${to}`)
        break

      case 'members':
        navigate(`${config.orgBaseUrl}/${orgId}/invite`)
        break
      case 'oauth':
        navigate(`${config.orgBaseUrl}/${orgId}/oauth`)
        break

      case 'pgBuilder':
        navigate(`${config.developerBaseUrl}/${orgId}`)
        break

      case 'integrations':
        navigate(`/integrations/${orgId}`)
        break

      case 'Logout':
        handleLogOut(navigate)
        break

      default:
        console.log(`Unhandled navigation case: ${to}`)
        break
    }
  }

  const ListArray = [
    [{ title: 'Switch Workspace', path: 'orgs' }],
    [
      { title: 'Workspace Settings', path: 'setting', selectedItem: 'setting' },
      { title: `Members (${usersList ? usersList.length : 0})`, path: 'members', selectedItem: 'invite' }
    ],
    [
      { heading: 'Developers' },
      { title: 'API Doc', path: 'https://techdoc.viasocket.com' },
      { title: 'OAuth', path: 'oauth', selectedItem: 'oauth' }
    ],
    [
      { heading: 'Do you own Saas Project?' },
      {
        title: 'Plugin Builder',
        subTitle: 'Create and customize plugins for extended functionality.',
        path: 'pgBuilder',
        selectedItem: config.developerBaseUrl
      },
      { title: 'Integrations', subTitle: 'Connect and configure third-party services.', path: 'integrations', selectedItem: 'integrations' }
    ],
    [{ title: 'Log Out', path: 'Logout' }]
  ]

  const location = useLocation()

  return (
    <Box className='column'>
      <Box className='flex-start-center gap-2 text-truncate pl-3 mt-3'>
        <Box onMouseEnter={() => setShowOnHover(true)} onMouseLeave={() => setShowOnHover(false)}>
          {showOnHover ? (
            <IconButton
              onClick={handleBackButton}
              sx={{
                width: '24px',
                height: '24px'
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          ) : (
            <IconWrapper
              size='24px'
              iconUrl={orgIcon}
              component={
                orgIcon ? (
                  <img src={orgIcon} alt='icon' width='100%' />
                ) : (
                  <Avatar variant='square'>
                    <Typography variant='bold'>{orgObj?.name?.toUpperCase()?.substring(0, 2) || ''}</Typography>
                  </Avatar>
                )
              }
            />
          )}
        </Box>
        <Box className='flex-verticalcenter cursor-pointer w-100' onClick={() => setOpen()}>
          <Typography
            variant='bold'
            className='ml-2'
            sx={{
              display: 'inline-block',
              maxWidth: '80%', // Explicitly 80%
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}
          >
            {orgObj?.name?.length > 17 ? `${orgObj?.name?.slice(0, 17)?.toLowerCase()}...` : orgObj?.name?.toLowerCase() || ''}
          </Typography>
          <Box className='flex-verticalcenter'>{!open ? <ExpandMoreIcon /> : <ExpandLessIcon />}</Box>
        </Box>
      </Box>
      {!open && <Divider variant='normal' className='mt-3' />}
      <Collapse in={open} timeout='auto' unmountOnExit>
        <Stack className='w-100'>
          {ListArray.map((group) => (
            <Box key={group}>
              <Box className='my-3'>
                {group.map((item) => (
                  <Box key={item} className={`${item?.heading && 'mb-2'}`}>
                    {item?.heading ? (
                      <Typography key={item} variant='h7' className='pl-3-5'>
                        {item.heading}
                      </Typography>
                    ) : (
                      <List key={item} disablePadding>
                        <ListItem
                          disablePadding
                          disableGutters
                          onClick={(e) => handleNavigations(e, item.path)}
                          className={`${location.pathname.includes(item.selectedItem) ? 'selected-list-item' : ''} w-100`}
                          sx={{ height: 'fit-content' }}
                          {...(item.title === 'API Doc'
                            ? {
                                component: RouterLink,
                                to: item.path,
                                target: '_blank',
                                rel: 'noopener noreferrer',
                                sx: { color: 'inherit', margin: 0, padding: 0 }
                              }
                            : {})}
                        >
                          <ListItemButton className='w-100 p-1'>
                            <Box className='pl-3 column gap-0-5'>
                              <Typography>{item.title}</Typography>
                              {item.subTitle && (
                                <Typography variant='caption' className='opacity-06 line-height'>
                                  {item.subTitle}
                                </Typography>
                              )}
                            </Box>
                          </ListItemButton>
                        </ListItem>
                      </List>
                    )}
                  </Box>
                ))}
              </Box>
              <Divider variant='normal' />
            </Box>
          ))}
        </Stack>
      </Collapse>
    </Box>
  )
}
export default React.memo(addUrlDataHoc(React.memo(SelectedWorkspace), [ParamsEnums.orgId, ParamsEnums.projectId]))
