import React from 'react'
import { Box, Typography } from '@mui/material'
import { ImageBar, GradientBarBG } from '../../assests/assestsIndex.ts'
import FeedbackModalV2 from '../../components/plugin/pluginComponents/modal/feedbackModalV2.tsx'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'

function ReviewBar({ setReview }) {
  return (
    <Box
      height='fit-content'
      position='relative'
      className='w-100 bg-white flex px-5 py-5 justify-between items-center'
      justifyContent='space-between'
      alignItems='center'
      overflow='hidden'
    >
      <img style={{ position: 'absolute', width: '100%', left: 0 }} className='absolute object-cover' src={GradientBarBG} alt='' />
      <img style={{ position: 'absolute', left: 0 }} className='absolute w-100 h-100 object-cover' src={ImageBar} alt='' />
      <Typography position='relative' zIndex='2' variant='subtitle1' color='#000'>
        <Typography component='span' variant='h6' color='#A8200D' fontStyle='italic' className='font-bold'>
          Smile
        </Typography>{' '}
        if we made your day, or let us know how we can improve!
      </Typography>

      <FeedbackModalV2 setReview={setReview} />
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(ReviewBar), []))
