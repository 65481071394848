import { CheckCircle } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import { Box, Card, Typography } from '@mui/material'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums } from '../../enums'
import PluginSearchBar from '../projectdashboard/PluginSearchBar.tsx'
import SelectedServices from './SelectedServices.tsx'
import './AppsExplore.scss'
import IconWrapper from '../IconWrapper/IconWrapper.tsx'
import { updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import CustomOptionsToCreateFlow from './CustomOptionsToCreateFlow.tsx'
import { addInAppIntegration } from '../../store/scripts/scriptsSlice'

type AppsExploreProps = {
  orgId: string
  projectId: string
  addmore?: boolean
  finalProjectId?: string
}

/**
 * AppsExplore component for exploring and integrating apps.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.orgId - The organization ID.
 * @param {string} props.projectId - The project ID.
 * @param {boolean} [props.addmore=false] - Flag to indicate if more apps can be added.
 * @param {string} [props.finalProjectId=''] - The final project ID, defaults to projectId if not provided.
 * @returns {JSX.Element} The rendered AppsExplore component.
 */

function AppsExplore({ orgId, projectId, addmore = false, finalProjectId = '', arrayOfAllApps = [] }: AppsExploreProps) {
  if (!finalProjectId) finalProjectId = projectId
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [servicesData, setServicesData] = useState({ isLoading: false, services: [] })
  const addService = (service) => {
    dispatch(
      addInAppIntegration({
        serviceId: service.rowid,
        serviceName: service.name,
        iconUrl: service.iconurl,
        projectId: finalProjectId || `proj${orgId}`
      })
    )
    if (addmore || finalProjectId) {
      dispatch(updateAppInfoState({ modalType: false, isModalOpen: false }))
      navigate(`/projects/${orgId}${finalProjectId ? `/${finalProjectId}` : ''}/appsexplore/servicepage/${service.rowid}`)
    }
  }
  return (
    <Box className=' overflow-scroll-y w-100 app-explore-container gap-2 p-3' sx={{ height: '95vh' }}>
      <Box className='p-2 column w-100 gap-4'>
        {finalProjectId ? <CustomOptionsToCreateFlow finalProjectId={finalProjectId} /> : null}
        <Typography variant='h6'>
          {' '}
          {!finalProjectId && !addmore ? (
            'Please select at least 3 apps to get recommendations.'
          ) : finalProjectId ? (
            <>Integrate a new app</>
          ) : (
            'Add new App'
          )}
        </Typography>
        <Box className='search-bar-parent w-100'>
          <PluginSearchBar
            getServices={({ servicesArray, isLoading }) => {
              setServicesData({
                isLoading: isLoading,
                services: servicesArray
                  ?.filter((service) => {
                    const actionExists = Number(service.actioncount)
                    const triggerExists = Number(service.triggercount)
                    if (!actionExists && !triggerExists) return false
                    return !addmore || !arrayOfAllApps.find((serviceid) => serviceid === service.rowid)
                  })
                  ?.slice(0, !addmore ? 30 : undefined)
              })
            }}
          />
        </Box>

        <Box className='flex-start-center gap-2  flex-wrap'>
          {servicesData?.services?.map((service) => {
            return (
              <Card
                key={service?.rowid}
                variant='outlined'
                className='service-block service-block__no-description gap-2 flex-start-center w-fitcontent'
                onClick={() => addService(service)}
              >
                <IconWrapper iconUrl={service.iconurl} size='28px' />
                {service.name}
                {(arrayOfAllApps || [])?.find((serviceid) => serviceid === service.rowid) && <CheckCircle color='success' />}
              </Card>
            )
          })}
        </Box>
      </Box>

      {!addmore && !finalProjectId && arrayOfAllApps?.length ? (
        <SelectedServices addmore={addmore} selectedServicesArray={arrayOfAllApps} />
      ) : null}
    </Box>
  )
}
export default React.memo(addUrlDataHoc(React.memo(AppsExplore), [ParamsEnums.orgId, ParamsEnums.projectId]))
