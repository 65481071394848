import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'

import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, IconButton, DialogContent, Button } from '@mui/material'
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined'
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined'
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded'
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard'

import SecurityIcon from '@mui/icons-material/Security'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ProjectList from '../../components/projectdashboard/projectList/projectList.tsx'
import CustomModal from '../../components/customModal/customModal'
import { fetchProjects } from '../../store/projects/projectsThunk'
import { errorToast } from '../../components/customToast'
import config from '../../config'
import './projectPage.scss'
import { updateAppInfoState, closeModalRedux } from '../../store/appInfo/appInfoSlice.ts'
import getAllUsers from '../../store/orgUsers/orgUsersThunk'
// import SearchBar from '../../components/projectdashboard/searchBar'
import { ApiTypes, ModalTypeEnums, ParamsEnums } from '../../enums'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { listOfUsers } from '../../store/orgUsers/orgUsersSelector'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import SelectedWorkspace from '../../components/workspaceComponent/selectedWorkspace.tsx'
import AuthorizeOrgModal from '../../components/oauthAuthorization/OauthAuthorizationModal'
import { switchOrgId } from '../../utils/utilities'
import PluginRequestForm from '../../components/plugin/pluginComponents/pluginRequestForm.tsx'
import CreateNewPopover from '../../components/projectdashboard/createNewPopover.tsx'
import VideoPlayer from '../../components/videoPlayer/videoPlayer.tsx'
import SupportDialog from '../supportModel/SupportDialog.tsx'
import selectActiveUser from '../../store/user/userSelector'
import { leaveCompany } from '../../api/index'
import { fetchOrgs } from '../../store/orgs/orgsThunk'
import CustomDialog from '../../components/customDialog/CustomDialog.tsx'

// eslint-disable-next-line

function ProjectPageV2(props: { orgId: any; projectId: any }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const projectSliderRef = useRef('')
  const orgId = props?.orgId
  const projectId = props?.projectId

  const { orgs, modalType, mode, appInfo, usersList, allProjects, currentOrgId, userData, currentOrgCreatedBy } = useCustomSelector(
    (state: $ReduxCoreType) => ({
      orgs: state?.orgs?.orgs,
      isModalOpen: state.appInfo.isModalOpen,
      modalType: state.appInfo.modalType,
      mode: state.appInfo.mode,
      appInfo: state.appInfo,
      usersList: listOfUsers(state),
      allProjects: state?.projects?.projects[orgId],
      currentOrgId: state.appInfo?.currentOrgId,
      userData: selectActiveUser(state),
      currentOrgCreatedBy: state.orgs?.orgs?.[orgId]?.created_by
    })
  )
  const activeProjects = allProjects?.['active'] || {}
  const deletedProjects = allProjects?.['deleted'] || {}

  const getUsersList = async () => {
    dispatch(getAllUsers({ orgId }))
  }
  const handleClose = () => {
    // setOpen(false)
    // setAnchorEl(null)getUsersList
    dispatch(closeModalRedux({}))
  }
  const checkProjectPageRoute = () => {
    const res = activeProjects[projectId] || deletedProjects[projectId]
    if (res === null && projectId) {
      navigate(`${config.projectsBaseUrl}`)
    }
  }

  useEffect(() => {
    checkProjectPageRoute()
  }, [projectId])

  useEffect(() => {
    const handleProjects = async () => {
      if (orgId?.length) {
        if (orgId !== currentOrgId) {
          try {
            await switchOrgId(orgId, orgId)
          } catch (error) {
            errorToast("You don't have access to this organization")
            navigate(`${config.orgBaseUrl}`)
          }
        }
        dispatch(updateAppInfoState({ currentOrgId: orgId }))
        if (mode !== ApiTypes.EMBED) dispatch(fetchProjects({ orgId, type: ApiTypes.FLOW }))
        getUsersList()
      }
    }
    handleProjects()
  }, [orgId])

  const handleMetrics = () => {
    navigate(`${config.orgBaseUrl}/${orgId}/metrics`)
  }
  const handleDashBoard = () => {
    navigate(`${config.projectsBaseUrl}/${orgId}`)
  }

  const [open, setOpen] = useState(false)

  const handleSelectedOpen = () => {
    setOpen(!open)
  }

  const getRandomOrg = () => {
    const orgsList = Object.entries(orgs)
    let [key, value] = orgsList?.[0]
    if (key === orgId) [key, value] = orgsList?.[1]
    return { key, name: value?.name }
  }

  const handleOptionsBtn = async (currentOrg) => {
    const { key, name } = getRandomOrg()
    await switchOrgId(key, name)
    await leaveCompany({ company_id: Number(currentOrg) })
    navigate(config.orgBaseUrl)
    dispatch(fetchOrgs())
  }
  const [openLeaveDialog, setLeaveDialog] = useState(false)

  const handleOpenLeaveDialog = () => setLeaveDialog(true)
  const handleCloseLeaveDialog = () => setLeaveDialog(false)

  return (
    <Box className='project-page-cont flex overflow-scroll-y' sx={{ background: open && 'var(--col-project-slider-dropdown)' }}>
      <Box ref={projectSliderRef} className='project-page flex-col w-100 h-100 p-0 gap-0'>
        {/* OrgComponent */}
        <Box className='flex-col'>
          <Box className='w-100'>
            <SelectedWorkspace open={open} setOpen={handleSelectedOpen} usersList={usersList} />
          </Box>

          {/* Seacrch everywhere scetion */}
        </Box>
        <Box className='mx-2' sx={{ display: !open ? 'block' : 'none' }}>
          <CreateNewPopover defaultProjectId={`proj${orgId}`} />

          <List className='mb-3'>
            <ListItem
              disablePadding
              onClick={handleDashBoard}
              className={`${location.pathname === `${config.projectsBaseUrl}/${orgId}` ? 'selected-list-item' : ''}`}
            >
              <ListItemButton>
                <ListItemIcon>
                  <SpaceDashboardIcon />
                </ListItemIcon>
                <ListItemText primary='Dashboard' />
                <ChevronRightIcon className='transform-8px' />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              onClick={handleMetrics}
              className={`${location.pathname.includes('metrics') ? 'selected-list-item' : ''}`}
            >
              <ListItemButton>
                <ListItemIcon>
                  <EqualizerRoundedIcon />
                </ListItemIcon>
                <ListItemText primary='Metrics' />
                <ChevronRightIcon className='transform-8px' />
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding
              onClick={() => navigate(`${config.authBaseUrl}/${orgId}`)}
              className={`${location.pathname.startsWith(config.authBaseUrl) ? 'selected-list-item' : ''} `}
            >
              <ListItemButton>
                <ListItemIcon>
                  <SecurityIcon />
                </ListItemIcon>
                <ListItemText primary='Connections' />
                <ChevronRightIcon className='transform-8px' />
              </ListItemButton>
            </ListItem>

            {/* list of all projects */}
            {/* <Box  className={location.pathname.includes('metrics') ? 'selected-list-item p-2' : 'list-item p-2 '}>
                  <Typography selected={location.pathname === `${config.orgBaseUrl}/${orgId}/metrics`} darkbg='true' variant='base'>
                    Metrics
                  </Typography>
                </Box> */}

            <Box>
              <ProjectList />
            </Box>
          </List>
          {/* list of all other menus */}
          <Box>
            <List className='flex-col'>
              <Box>
                <ListItem
                  disablePadding
                  className={`${location.pathname.includes('chatbot') ? 'selected-list-item' : ''} `}
                  onClick={() => navigate(`${config.orgBaseUrl}/${orgId}/chatbot`)}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <AutoFixHighOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Ask AI' />
                    <ChevronRightIcon className='transform-8px' />
                  </ListItemButton>
                </ListItem>

                <ListItem
                  disablePadding
                  onClick={() => {
                    dispatch(updateAppInfoState({ modalType: `modelForOpenTalktosupport` }))
                  }}
                >
                  <ListItemButton target='_blank'>
                    <ListItemIcon>
                      <HeadsetMicOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Talk to Support' />
                    <ChevronRightIcon className='transform-8px' />
                  </ListItemButton>
                </ListItem>
              </Box>
              {modalType && (
                <CustomModal
                  fullScreen
                  width='70vw'
                  // bgcolor='#B8FCDB'
                  height='90vh'
                  openModal={modalType === `modelForOpenTalktosupport`}
                  onClose={() => dispatch(closeModalRedux({}))}
                >
                  <IconButton variant='iconbuttonModal' aria-label='close' className='w-fitcontent' onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                  <DialogContent className='m-auto max-width-800px'>
                    <SupportDialog />
                  </DialogContent>
                </CustomModal>
              )}
            </List>
          </Box>
        </Box>
        {open && userData?.userId !== currentOrgCreatedBy && (
          <ListItem className='mt-auto mb-3' disablePadding>
            <ListItemButton className='pl-3' onClick={handleOpenLeaveDialog}>
              <Typography>Leave Workspace</Typography>
            </ListItemButton>
          </ListItem>
        )}
      </Box>
      <CustomModal
        className='orgbox__model'
        fullScreen={false}
        openModal={appInfo.isModalOpen && appInfo.modalType === ModalTypeEnums.AUTHORIZE_ORG}
        // openModal={appInfo.isModalOpen }
      >
        <AuthorizeOrgModal selectedOrg={orgs[orgId]} />
      </CustomModal>
      <PluginRequestForm />
      <CustomDialog
        open={openLeaveDialog}
        onClose={handleCloseLeaveDialog}
        title='Are you sure you want to leave this Workspace?'
        content='By leaving this Workspace, you will lose access to its resources and data. This action is irreversible, and you will no longer be able to view or interact with this Workspace. Do you wish to proceed?'
        actions={
          <Box className='flex gap-2'>
            <Button variant='outlined' onClick={handleCloseLeaveDialog} autoFocus sx={{ px: 4, py: 1 }}>
              No
            </Button>
            <Button variant='contained' onClick={() => handleOptionsBtn(orgId)} sx={{ px: 4, py: 1 }}>
              Yes
            </Button>
          </Box>
        }
      />
      <VideoPlayer
        openModal={['webhook', 'email', 'cron'].includes(modalType)}
        type={modalType}
        onClose={() => dispatch(closeModalRedux())}
      />
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(ProjectPageV2), [ParamsEnums.projectId, ParamsEnums.orgId]))
