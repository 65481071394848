import { useNavigate } from 'react-router-dom'
import { Box, Card, Typography } from '@mui/material'
import WebhookIcon from '@mui/icons-material/Webhook'
import React, { useMemo } from 'react'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums, Tabnames } from '../../../enums'
import { useCustomSelector } from '../../../utils/deepCheckSelector'
import config from '../../../config'
import IconWrapper from '../../IconWrapper/IconWrapper.tsx'
import { RenderIcons } from '../../../utils/utilities'
import { getAppIntegrationIds } from '../../../store/scripts/scriptsSelector'
import EditPencil from '../../Icons/EditPencil.tsx'
import TriggerErrorTooltipIcon from '../../projectdashboard/triggerErrorComponent.tsx'

function getCommonScriptsBetweenServices(service1, service2) {
  const service1scripts = service1?.active || []
  const service2scripts = service2?.active || []

  const commonScripts = service1scripts.filter((script) => service2scripts.includes(script))

  return commonScripts
}

type FlowFooterProps = {
  flow: {
    status: string
    metadata?: {
      published?: {
        versioning?: any[]
      }
    }
    json_script?: {
      blocks: any[]
    }
  }
}

function Flowfooter({ flow }: FlowFooterProps) {
  return (
    <Box className=' w-100 flex-spaceBetween-center'>
      <RenderIcons flowJsonBlocks={flow?.json_script?.blocks} size='16px' />
    </Box>
  )
}

type EnabledFlowsProps = {
  projectId?: string
  firstServiceId: string
  orgId: string
  serviceId?: string
  eventId?: string
  isEmbedUrl?: boolean
}

interface FlowCardProps {
  script: any
  orgId: string
  serviceId?: string
  firstServiceId: string
  isEmbedUrl?: boolean
  navigate: (url: string) => void
  active?: boolean
}

function FlowCard({ script, orgId, serviceId, firstServiceId, isEmbedUrl, navigate, active = false }: FlowCardProps) {
  const serviceIdToNavigate = serviceId || firstServiceId
  const flowUrl = isEmbedUrl
    ? `/integrations/embed/${orgId}/${script?.project_id}${serviceId ? `/service/${serviceId}` : ''}/workflow/${script.id}/${
        Tabnames.DRAFT
      }`
    : `${config.projectsBaseUrl}/${orgId}/${script?.project_id}${serviceIdToNavigate ? `/service/${serviceIdToNavigate}` : ''}${
        config.workflowBaseUrl
      }/${script.id}/${Tabnames.DRAFT}`

  return (
    <Box className='grid-item p-2' key={script.id}>
      <Card
        variant='outlined'
        onClick={() => navigate(flowUrl)}
        className={`service-block flex-col-spaceBetween-start ${active ? 'selected-block-light' : ''} `}
      >
        <Box className='w-100' sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box className='flex-center-center'>
            {script?.json_script?.trigger?.iconUrl ? (
              <IconWrapper iconUrl={script?.json_script?.trigger?.iconUrl} size='32px' />
            ) : (
              <IconWrapper component={<WebhookIcon className='w-100 h-100' />} size='32px' />
            )}
            <span className='pl-2'>{script?.title || '[Untitled]'}</span>
          </Box>
          <Box>
            <TriggerErrorTooltipIcon scriptId={script.id} />{' '}
            {Boolean(Object.entries(script?.metadata?.drafted?.versioning || {})?.length) && <EditPencil />}
          </Box>
        </Box>

        <Typography className='service-description'>{script?.metadata?.description}</Typography>
        <Flowfooter flow={script} />
      </Card>
    </Box>
  )
}

/**
 * EnabledFlows component displays a list of enabled flows for a given project and service.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} [props.projectId] - The ID of the project
 * @param {string} props.firstServiceId - The ID of the first service
 * @param {string} props.orgId - The ID of the organization
 * @param {string} [props.serviceId] - The ID of the second service (optional)
 * @param {string} [props.eventId] - The ID of the event (optional)
 * @param {boolean} [props.isEmbedUrl] - Flag indicating if the component is used in an embedded URL
 *
 * @returns {JSX.Element|null} The rendered EnabledFlows component or null if no enabled flows
 *
 * @description
 * This component fetches and displays a list of enabled flows based on the provided service and event IDs.
 * It allows navigation to individual flow pages and shows the status of each flow.
 */
function EnabledFlows({ projectId, firstServiceId, orgId, serviceId, eventId, isEmbedUrl }: EnabledFlowsProps) {
  const navigate = useNavigate()
  const { scriptList, enabledPluginsObject } = useCustomSelector((state) => ({
    scriptList: Object.values(state.scripts.scripts)
      .filter((script) => !projectId || script.project_id === projectId)
      .reduce((acc, script) => {
        acc[script.id] = script
        return acc
      }, {}),
    enabledPluginsObject: getAppIntegrationIds(state, projectId)
  }))

  const scriptIdList = useMemo(() => {
    let arrayOfScriptids = enabledPluginsObject?.[firstServiceId]?.active || []
    if (eventId)
      arrayOfScriptids =
        enabledPluginsObject?.[firstServiceId]?.triggers?.[eventId] || enabledPluginsObject?.[firstServiceId]?.actionid?.[eventId] || []
    else if (serviceId)
      arrayOfScriptids = enabledPluginsObject?.[serviceId]
        ? getCommonScriptsBetweenServices(enabledPluginsObject?.[firstServiceId], enabledPluginsObject?.[serviceId])
        : []

    return arrayOfScriptids
  }, [eventId, enabledPluginsObject, serviceId, firstServiceId])

  const activeFlows = scriptIdList?.filter((id) => scriptList[id]?.metadata?.published?.versioning?.length)
  const draftFlows = scriptIdList?.filter((id) => !scriptList[id]?.metadata?.published?.versioning?.length)

  return (
    <Box className='column w-100 pt-2'>
      {activeFlows?.length ? (
        <Typography variant='smallHeading' className='px-2'>
          Active Flows
        </Typography>
      ) : null}
      <Box className='container w-100'>
        {activeFlows?.map((scriptId) => {
          const script = scriptList[scriptId]
          return script ? (
            <FlowCard
              active
              script={script}
              orgId={orgId}
              serviceId={serviceId}
              firstServiceId={firstServiceId}
              isEmbedUrl={isEmbedUrl}
              navigate={navigate}
            />
          ) : null
        })}
      </Box>

      {draftFlows?.length ? (
        <Typography variant='smallHeading' className='px-2 mt-4'>
          Draft Flows
        </Typography>
      ) : null}
      <Box className='container w-100'>
        {draftFlows?.map((scriptId) => {
          const script = scriptList[scriptId]
          return script ? (
            <FlowCard
              script={script}
              orgId={orgId}
              serviceId={serviceId}
              firstServiceId={firstServiceId}
              isEmbedUrl={isEmbedUrl}
              navigate={navigate}
            />
          ) : null
        })}
      </Box>
    </Box>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(EnabledFlows), [
    ParamsEnums.orgId,
    ParamsEnums.projectId,
    ParamsEnums.scriptId,
    ParamsEnums.isTemplate,
    ParamsEnums.sectionKey,
    ParamsEnums.serviceId,
    ParamsEnums.eventId,
    ParamsEnums.isEmbedUrl
  ])
)
