import React, { useMemo } from 'react'
import MarkdownIt from 'markdown-it'
import insert from 'markdown-it-ins'

// LinkRenderer component for rendering links
const LinkRenderer = React.memo(({ href, children }) => (
  <a href={href} target='_blank' rel='noreferrer'>
    {children}
  </a>
))

LinkRenderer.displayName = 'LinkRenderer'

function CustomMarkdown({ help = '', children = '', ...rest }) {
  // Create the MarkdownIt instance and apply the insert plugin
  const md = useMemo(() => {
    const markdown = new MarkdownIt()
    markdown.use(insert) // Add the 'insert' plugin
    return markdown
  }, [])

  // Render the markdown using MarkdownIt
  const renderedContent = md.render(help || children || '')

  return (
    <div {...rest}>
      {/* Render the markdown content with custom link renderer */}
      <div dangerouslySetInnerHTML={{ __html: renderedContent }} />
    </div>
  )
}

export default CustomMarkdown
