// src/components/ShareButton.js
import React from 'react'
import IconButton from '@mui/material/IconButton'
import FacebookIcon from '@mui/icons-material/Facebook'
import XIcon from '@mui/icons-material/X'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { Typography } from '@mui/material'

function ShareButton({ platform, url, title, description, hashtags }) {
  const encodedURL = encodeURIComponent(url)
  const encodedTitle = encodeURIComponent(title)
  const encodedDescription = encodeURIComponent(description)
  const encodedHashtags = encodeURIComponent(hashtags?.join(','))

  let shareURL = ''
  let IconComponent = null
  let tooltipTitle = ''

  switch (platform) {
    case 'Facebook':
      shareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodedURL}&quote=${encodedTitle}`
      IconComponent = <FacebookIcon />
      tooltipTitle = 'Share on Facebook'
      break
    case 'X':
      shareURL = `https://x.com/intent/post?url=${encodedURL}%0A&text=${encodedTitle}%0A%0A${encodedDescription}%0A&hashtags=${encodedHashtags}`
      IconComponent = <XIcon />
      tooltipTitle = 'Share on X'
      break
    case 'Linkedin':
      shareURL = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedURL}&title=${encodedTitle}&summary=${encodedDescription}&source=${encodedURL}`
      IconComponent = <LinkedInIcon />
      tooltipTitle = 'Share on LinkedIn'
      break

    case 'Whatsapp':
      shareURL = `https://web.whatsapp.com/send?text=${encodedTitle}%0A${encodedDescription}%0A${encodedURL}`
      IconComponent = <WhatsAppIcon />
      tooltipTitle = 'Share on WhatsApp'
      break
    default:
      return null
  }

  const openShareWindow = () => {
    window.open(shareURL, '_blank', 'noopener,noreferrer')
  }

  const platformColors = {
    Facebook: '#3b5998',
    X: '#000',
    Linkedin: '#0077b5',
    Whatsapp: '#25d366'
  }

  return (
    <IconButton
      onClick={openShareWindow}
      sx={{
        color: '#fff',
        '&:hover': {
          backgroundColor: platformColors[platform],
          '&:hover span': {
            transform: 'scale(1.1)'
          }
        },
        backgroundColor: platformColors[platform],
        width: '100%', // Default width
        height: 60,
        flexShrink: 1,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        borderTop: '2px solid #000', // Make outer border transparent
        borderLeft: 'none', // Remove left border
        '&:not(:last-child)': {
          borderRight: '2px solid #000' // Add border on the right of each item except last one
        }
      }}
      aria-label={tooltipTitle}
    >
      <Typography
        component='span'
        sx={{
          opacity: 1,
          visibility: 'visible',
          transition: 'all .3s ease-in-out',
          position: 'relative',
          whiteSpace: 'nowrap',
          fontSize: 14,
          color: '#fff',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          gap: '1vmax'
        }}
      >
        {IconComponent}
      </Typography>
    </IconButton>
  )
}

export default ShareButton
