import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Chip, LinearProgress, Typography } from '@mui/material'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import { useCustomSelector } from '../../../utils/deepCheckSelector'
import { MiscTypes, ParamsEnums } from '../../../enums'
import { getActionAndTrigger } from '../../../react-query/allPluginsData/allPluginsDataQueries.ts'
import IconWrapper from '../../IconWrapper/IconWrapper.tsx'
import { updateAppInfoState } from '../../../store/appInfo/appInfoSlice.ts'
import RenderCombinationComponents from './RenderCombinationComponents.tsx'
import ShowMoreButton from './ShowMoreButton.tsx'

function FeaturedCombinationsComponent({
  orgId,
  projectId,
  editor = false,
  createflow = () => {},
  filter,
  combinations = {},
  isDefault = false
}) {
  const dispatch = useDispatch()
  const [showless, setShowless] = useState(true)
  const { integrationConfig, currentIntegrationSettings, eventsNotallowedMultiple } = useCustomSelector((state) => ({
    integrationConfig:
      state.appInfo.mode === MiscTypes.EMBED_MODE
        ? state.projects.embedProject[orgId]?.['active']?.[projectId]?.settings?.config
        : state.appInfo.localConfiguration,
    currentIntegrationSettings: state.projects.embedProject[orgId]?.['active']?.[projectId]?.settings || {},
    eventsNotallowedMultiple: state.projects.embedProject?.[orgId]?.['active']?.[projectId]?.settings?.permitsingleflow || {}
  }))

  const { data: featuredCombinationEvents, isLoading } = getActionAndTrigger(filter)
  if (isLoading)
    return (
      <Box className='w-100 py-3'>
        {' '}
        <LinearProgress />{' '}
      </Box>
    )
  const combinationsArray = Object.keys(combinations || {})
  if (editor)
    return (
      <Box className='w-100 flex-start-center gap-3 flex-wrap py-3'>
        {combinationsArray?.map((combination) => {
          const [triggerevent, pluginevent] = combination.split('_')
          const trigger = featuredCombinationEvents?.find((el) => el.rowid === triggerevent)
          const plugin = featuredCombinationEvents?.find((el) => el.rowid === pluginevent)
          // if ((!trigger && triggerevent !== 'webhook') || !plugin) return null
          return (
            <Chip
              size='large'
              variant='outlined'
              key={combination}
              icon={
                <Box className='flex-start-center gap-1'>
                  {trigger?.iconurl && <IconWrapper iconUrl={trigger?.iconurl} size='18px' />}
                  <IconWrapper iconUrl={plugin?.iconurl} size='18px' />
                </Box>
              }
              onDelete={
                isDefault
                  ? null
                  : () => {
                      const featuredCombinationsDummy = { ...combinations }
                      delete featuredCombinationsDummy[combination]
                      dispatch(
                        updateAppInfoState({
                          localConfiguration: { ...integrationConfig, featuredCombinations: featuredCombinationsDummy }
                        })
                      )
                    }
              }
              label={
                triggerevent !== 'webhook'
                  ? ` ${trigger?.name} in ${trigger?.pluginname} + ${plugin?.name} in ${plugin?.pluginname}`
                  : `${plugin?.name} in ${plugin?.pluginname}`
              }
            />
          )
        })}
      </Box>
    )
  return combinationsArray?.length ? (
    <Box className='my-2 w-100'>
      <Typography variant='h6' className='ml-2'>
        Top Integrations
      </Typography>

      <Box className='container'>
        {combinationsArray?.slice(0, showless ? 8 : combinationsArray.length)?.map((combination) => {
          const [triggerevent, pluginevent] = combination.split('_')
          if (eventsNotallowedMultiple[triggerevent] && eventsNotallowedMultiple['enabledTriggers']?.triggerevent) return null

          const trigger = featuredCombinationEvents?.find((el) => el?.rowid === triggerevent) || { name: 'webhook', rowid: 'webhook' }
          const plugin = featuredCombinationEvents?.find((el) => el?.rowid === pluginevent)
          // if ((!trigger && triggerevent !== 'webhook') || !plugin) return null

          const eventObj = currentIntegrationSettings?.serviceId === trigger?.pluginrecordid ? plugin : trigger

          const dataToshow = (
            <Box className={`gap-3 ${trigger?.rowid === 'webhook' ? 'flex-start-center' : 'column'} `}>
              <Box className='flex-start-center gap-2'>
                {trigger?.iconurl && <IconWrapper iconUrl={trigger?.iconurl} size='40px' />}
                <IconWrapper iconUrl={plugin?.iconurl} size='40px' />
              </Box>
              <Typography className='service-description'>
                {triggerevent !== 'webhook'
                  ? `When ${trigger?.name} in ${trigger?.pluginname} perform ${plugin?.name} in ${plugin?.pluginname}`
                  : `${plugin?.name} in ${plugin?.pluginname}`}
              </Typography>
            </Box>
          )
          return (
            <RenderCombinationComponents
              key={combination}
              createflow={createflow}
              eventObj={eventObj}
              eventNotallowed={eventsNotallowedMultiple[triggerevent]}
              dataToshow={dataToshow}
              triggerEvent={trigger}
              pluginEvent={plugin}
            />
          )
        })}

        {showless && <ShowMoreButton leftCombinations={combinationsArray?.slice(8)} setShowMore={() => setShowless(false)} />}
      </Box>
    </Box>
  ) : null
}
export default React.memo(addUrlDataHoc(React.memo(FeaturedCombinationsComponent), [ParamsEnums.projectId, ParamsEnums.orgId]))
