import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

interface customDialogProps {
  open: boolean
  onClose: () => void
  title?: string
  content?: string | React.ReactNode
  actions?: React.ReactNode
}

const CustomDialog: React.FC<customDialogProps> = React.memo(({ open, onClose, title, content, actions }) => {
  const memoizedDialog = React.useMemo(
    () => (
      <Dialog open={open} onClose={onClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        {title && <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>}
        {content && (
          <DialogContent>
            {typeof content === 'string' ? <DialogContentText id='alert-dialog-description'>{content}</DialogContentText> : content}
          </DialogContent>
        )}
        {actions && <DialogActions>{actions}</DialogActions>}
      </Dialog>
    ),
    [open, onClose, title, content, actions]
  )

  return memoizedDialog
})

CustomDialog.displayName = 'CustomDialog'

export default CustomDialog
