import { Box, Button, Typography } from '@mui/material'
import ChatIcon from '@mui/icons-material/Chat'
import CallIcon from '@mui/icons-material/Call'
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import BugReportIcon from '@mui/icons-material/BugReport'
// import { Link } from 'react-router-dom'
// import { useDispatch } from 'react-redux'
// import { openModalRedux } from '../../store/appInfo/appInfoSlice.ts'
import ChatWidget from './ChatWidget'

function ContactUs({ launcher, setLauncher }) {
  //   const dispatch = useDispatch()

  const toggleChatWidget = () => {
    if (launcher) {
      // Close the chat widget
      if (window.chatWidget) {
        window.chatWidget.close()
      }
    }

    if (!launcher) {
      // Open the chat widget
      if (window.chatWidget) {
        window.chatWidget.open()
      }
    }

    // Toggle the launcher state
    setLauncher(!launcher)
  }

  return (
    <Box className='w-100'>
      <ChatWidget />
      <Box className='w-fit-content p-5'>
        <Typography variant='h3' fontFamily='Times New Roman'>
          Contact our friendly team
        </Typography>
        <Typography color='#6B6B6B' fontWeight={600} variant='subtitle2'>
          Let us know how we can help.
        </Typography>
      </Box>
      <Box className='w-100 flex'>
        <Box className='p-4 flex w-100' border='1px solid #e0e0e0'>
          <b>Join our journey!</b> &nbsp; By contributing to our sprint let&apos;s build something amazing together!
        </Box>
        {/* <Link
          component='button'
          onClick={() => {
            dispatch(openModalRedux({ modalType: 'requestPlug' }))
          }}
          style={{ textDecoration: 'none' }}
        >
          <Button
            className='p-4 px-5 flex gap-1'
            whiteSpace='nowrap'
            sx={{ borderTop: '1px solid #e0e0e0', borderLeft: '1px solid #e0e0e0'  }}
          >
            <GridViewRoundedIcon />
            Request a Feature
          </Button>
        </Link> */}

        <Button
          component='a'
          href='https://dev-flow.viasocket.com/frill-login'
          target='_blank'
          className=' flex gap-1'
          whiteSpace='nowrap'
          sx={{ padding: '0.5rem 3rem', border: '1px solid #e0e0e0', borderLeft: '1px solid #e0e0e0' }}
        >
          {/* <BugReportIcon />
            Report a Bug */}
          <GridViewRoundedIcon />
          Request a Feature
        </Button>
        <Button
          component='a'
          href='https://dev-flow.viasocket.com/frill-login'
          target='_blank'
          className=' flex gap-1'
          whiteSpace='nowrap'
          sx={{ padding: '0.5rem 3rem', bgcolor: '#A8200D', color: '#fff' }}
        >
          <BugReportIcon />
          Report a Bug
          {/* <GridViewRoundedIcon />
            Request a Feature */}
        </Button>
      </Box>
      <Box className='w-100 flex'>
        <Button
          component='a'
          href='mailto:sales@viasocket.com'
          className='w-100 flex gap-2 p-4 py-2'
          sx={{ border: '1px solid #e0e0e0', justifyContent: 'flex-start', alignItems: 'flex-start' }}
        >
          <ChatIcon />
          <Typography variant='subtitle1'>Chat to sales</Typography>
        </Button>
        <Button
          component='a'
          href='tel:+918889578616'
          className='w-100 flex gap-2 p-4 py-2'
          sx={{ border: '1px solid #e0e0e0', justifyContent: 'flex-start', alignItems: 'center' }}
        >
          <CallIcon />
          <Typography variant='subtitle1'>Call us</Typography>
        </Button>
        <Button
          onClick={toggleChatWidget}
          className='w-100 flex gap-2 p-4 py-2'
          whiteSpace='nowrap'
          sx={{
            bgcolor: launcher ? '#000' : 'none',
            color: launcher ? '#fff' : '#000',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <HeadsetMicIcon />
          <Typography variant='subtitle1'>Chat to support</Typography>
        </Button>
      </Box>
    </Box>
  )
}

export default ContactUs
